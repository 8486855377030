export const loginPage = "/";
export const signUpPage = "/signUp";

export const dashboardPage = "/dashboard";
export const reviewPage = "/review";
export const reportPage = "/report";
export const reportNotMappedPage = "/reportNotMapped";
export const reportPendingActionPage = "/reportPendingAction";
export const reportUnableMappedPage = "/reportUnableMapped";
export const reportSSToMGPage = "/reportSSToMG";
export const reportSSToMSPage = "/reportSSToMS";
export const reportSGToMGPage = "/reportSGToMG";
export const reportRecommendationPage = "/reportRecommendation";
export const reportAddedMappingPage = "/reportAddedMapping";
export const hierarchyPage = "/hierarchy";