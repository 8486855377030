import React from "react";
import "./loader.scss";

const Loader = ({ loading, doNotOverFlow }) => {
  // useEffect(() => {
  //   if (!doNotOverFlow) {
  //     if (loading) {
  //       document.body.style.overflow = "hidden";
  //     } else {
  //       document.body.style.overflow = "unset";
  //     }
  //   }
  // }, [loading, doNotOverFlow]);

  if (!loading) {
    return "";
  }

  return (
    <div className="back-drop">
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
