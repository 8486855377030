import { formatDate } from "./formatDate";

export const getLocalStorageValue = (key) => {
  return localStorage?.getItem(key);
};

export const setLocalStorageValue = (key, value) => {
  return localStorage?.setItem(key, value);
};

export const removeValueFromLocalStorage = (keys) => {
  if (Array.isArray(keys)) {
    return keys.map((key) => localStorage?.removeItem(key));
  } else {
    return localStorage?.removeItem(keys);
  }
};

export const setCompanyDateFromLocalStorage = () => {
  let currentDate = '01-26-2021';
  let currentDateProdComp = '01-27-2021';
  removeValueFromLocalStorage([
    "currentDate",
    "currentDateProdComp"
  ]);
  let companyLogo = localStorage.getItem("companyLogo");
  /*if (companyLogo.includes('kehe') || 
  companyLogo.includes('purina')) {
    currentDate = '11-17-2020';
    currentDateProdComp = '11-18-2020';
  } else if (companyLogo.includes('mars') || 
  companyLogo.includes('champion-petfoods') || 
  companyLogo.includes('midwestern-pet-foods') || 
  companyLogo.includes('fromm-family') || 
  companyLogo.includes('colgate-palmolive') || 
  companyLogo.includes('kong-company') || 
  companyLogo.includes('nulo') || 
  companyLogo.includes('kln-family') || 
  companyLogo.includes('primal-pet-foods') || 
  companyLogo.includes('zoo-med') || 
  companyLogo.includes('natures-variety') || 
  companyLogo.includes('nestle') || 
  companyLogo.includes('diamond-pet-foods') || 
  companyLogo.includes('wellpet') || 
  companyLogo.includes('stella-chewy')) {
    currentDate = '10-26-2020';
    currentDateProdComp = '10-27-2020';
  } else {*/
    //currentDate = '01-10-2022';
    //20211229
    //currentDateProdComp = '01-11-2022';

    var currentDateDay = new Date((new Date()).valueOf() - 1000*60*60*24*1);
    var currentDateProdCompDay = new Date((new Date()).valueOf());
    currentDate = formatDate(currentDateDay, "-", true);
    currentDateProdComp = formatDate(currentDateProdCompDay, "-", true);
  //}
  setLocalStorageValue("currentDate", currentDate);
  setLocalStorageValue("currentDateProdComp", currentDateProdComp);
  return true;
};
