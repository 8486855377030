import { faSort, faSortAlphaDownAlt, faSortAlphaUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RowLabel = (props) => {
    const {
        sort,
        setSort,
        label,
        value
    } = props;

    const sortArray = sort.split("|")
    return (<a
        onClick={e => {
            if (sortArray[1] === value) {
                if (sortArray[0] === 'asc') {
                    setSort("desc|" + value);
                } else {
                    setSort("asc|" + value);
                }
            } else {
                setSort("asc|" + value);
            }
        }}
        title={label}
        className="btn btn-link text-decoration-none p-0 fw-bold text-dark">
            {label}<FontAwesomeIcon
            className={(sortArray[1] === value) ? "ms-1" : "opacity-25 ms-1"}
            icon={(sortArray[1] === value) ? (sortArray[0] === 'asc' ? faSortAlphaUp : faSortAlphaDownAlt) : (faSort)}
        />
    </a>);
};

export default RowLabel