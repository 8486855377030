import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import * as pageRoutes from "./pageRoutes";

import PageRoutingSkeleton from "./components/common/PageRoutingSkeleton/PageRoutingSkeleton";
import Layout from "./components/common/Layout/Layout";

import Login from "./components/Login/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import Report from "./components/Report/Report";

const Routess = () => {
    const routingStateLoader = () => {
        return (
            <Layout>
                <PageRoutingSkeleton />
            </Layout>
        );
    };
    return (
        <Router>
            <Suspense fallback={routingStateLoader()}>
                <Routes>
                    <Route exact path={pageRoutes.loginPage} element={<Login />} />
                    <Route exact path={pageRoutes.dashboardPage} element={<Layout><Dashboard /></Layout>} />
                    <Route exact path={pageRoutes.reportPage} element={<Layout><Report /></Layout>} />
                    <Route exact path={pageRoutes.reportNotMappedPage} element={<Layout><Report /></Layout>} />
                    <Route exact path={pageRoutes.reportPendingActionPage} element={<Layout><Report /></Layout>} />
                    <Route exact path={pageRoutes.reportUnableMappedPage} element={<Layout><Report /></Layout>} />
                    <Route exact path={pageRoutes.reportSSToMGPage} element={<Layout><Report /></Layout>} />
                    <Route exact path={pageRoutes.reportSSToMSPage} element={<Layout><Report /></Layout>} />
                    <Route exact path={pageRoutes.reportSGToMGPage} element={<Layout><Report /></Layout>} />
                    <Route exact path={pageRoutes.reportRecommendationPage} element={<Layout><Report /></Layout>} />
                    <Route exact path={pageRoutes.reportAddedMappingPage} element={<Layout><Report /></Layout>} />
                </Routes>
            </Suspense>
        </Router>
    );
};

export default Routess;