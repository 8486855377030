import React, { useEffect, useState } from "react";
import * as pageRoutes from "../../pageRoutes";
import { addParamsToLink } from "../common/common.js";
import { NavLink, useLocation } from "react-router-dom";
import { Col, Row, Tab, Table, Tabs, Form, ButtonGroup, Navbar, Nav, NavDropdown } from "react-bootstrap";
import liftRightData from "../Dashboard/left-right.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import Summary from "./Summary";
import ReportNotMapped from "./ReportNotMapped";
import ReportPendingAction from "./ReportPendingAction";
import ReportUnableMapped from "./ReportUnableMapped";
import ReportOtherException from "./ReportOtherException";
import Loader from "../common/Loader/Loader";

const Report = () => {
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [meppList, setMapList] = useState({});
    const [bungeeList, setBungeeList] = useState([]);
    const [segmentLib, setSegmentLib] = useState(["Pets", "Grocery", "Office Supplies"]);

    useEffect(() => {
        let mapping = {};
        let bungeeListlKeys = {};
        liftRightData.map(liftRightDataSingle => {
            let leftSide = liftRightDataSingle.categoryLeft + ">" + liftRightDataSingle.subcategoryLeft + ">" + [liftRightDataSingle.sub_subcategoryLeft, liftRightDataSingle.sub_sub_subcategoryLeft].filter(single => single.length).join(' | ') + ">" + liftRightDataSingle.SpecificGenericLeft;
            let rightSide = liftRightDataSingle.categoryRight + ">" + liftRightDataSingle.subcategoryRight + ">" + liftRightDataSingle.sub_subcategoryRight + ">" + liftRightDataSingle.SpecificGenericRight;
            if (!(leftSide in mapping)) {
                mapping[leftSide] = [];
            }
            mapping[leftSide].push(rightSide);
            if (!(rightSide in bungeeListlKeys)) {
                bungeeListlKeys[rightSide] = "";
            }
        });
        setMapList(mapping);
        setBungeeList(Object.keys(bungeeListlKeys));
    },[]);

    const leftRight = () => {
        return (<>
        <Row className="mt-2">
            <Col sm="5" className="border-end"><h6>Source</h6></Col>
            <Col sm="5"><h6>Target</h6></Col>
        </Row>
        {Object.keys(meppList).filter(meppListSingle => {
            return (meppList[meppListSingle].length >= 2);
        }).slice(0,5).map(maplistSingle => {
            let meppListSingleArr = maplistSingle.split('>');
            return (<Row>
                <Col sm="5" className="border-end">
                    <Table bordered size="sm">
                        <thead>
                            <tr>
                                <th>Retailer</th>
                                <th>Segment</th>
                                <th>Cat</th>
                                <th>Sub Cat</th>
                                <th>Sub Sub Cat</th>
                                <th>Type</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Petco</td>
                                <td>Pets</td>
                                <td>{meppListSingleArr[0]}</td>
                                <td>{meppListSingleArr[1]}</td>
                                <td>{meppListSingleArr[2]}</td>
                                <td>Generic</td>
                                <td>
                                    <ButtonGroup size="sm" className="float-end">
                                        <button className="btn btn-outline-dark btn-sm"><FontAwesomeIcon icon={faLink} /></button>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col sm="7">
                    <Table bordered size="sm">
                        <thead>
                            <tr>
                                <th>Retailer</th>
                                <th>Segment</th>
                                <th>Cat</th>
                                <th>Sub Cat</th>
                                <th>Sub Sub Cat</th>
                                <th>Type</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {meppList[maplistSingle].map(maplistSingleVal => {
                                let meppListSingleArrArr = maplistSingleVal.split('>');
                                return (<tr>
                                    <td>Bungee</td>
                                    <td>Pets</td>
                                    <td>{meppListSingleArrArr[0]}</td>
                                    <td>{meppListSingleArrArr[1]}</td>
                                    <td>{meppListSingleArrArr[2]}</td>
                                    <td>Generic</td>
                                    <td>Removed Mapping</td>
                                    <td>
                                        <ButtonGroup size="sm" className="float-end">
                                            <button className="btn btn-outline-dark btn-sm">Map</button>
                                            <button className="btn btn-outline-dark btn-sm"><FontAwesomeIcon icon={faLink} /></button>
                                        </ButtonGroup>
                                    </td>
                                </tr>);
                            })}
                        </tbody>
                    </Table>
                </Col>
                <Col sm="12"><hr/></Col>
            </Row>);
            })}</>);
    };

    const generateNavs = () => {
        return (<>
            <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.reportPage)}>Summary</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.reportNotMappedPage)}>Not Mapped</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.reportPendingActionPage)}>In Review</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.reportUnableMappedPage)}>Unable to Map</NavDropdown.Item>
            <NavDropdown.Item className={[pageRoutes.reportSSToMGPage, pageRoutes.reportSSToMSPage, pageRoutes.reportSGToMGPage, pageRoutes.reportRecommendationPage, pageRoutes.reportAddedMappingPage].includes(location.pathname) ? "active" : ""} as={NavLink} to={addParamsToLink(pageRoutes.reportSSToMGPage)}>Other Exception</NavDropdown.Item>
        </>);
    };

    const renderReport = () => {
        if (location.pathname == pageRoutes.reportPage) {
            return <Summary id="1" />;
        } else if (location.pathname == pageRoutes.reportNotMappedPage) {
            return <ReportNotMapped id="1" />;
        } else if (location.pathname == pageRoutes.reportPendingActionPage) {
            return <ReportPendingAction id="1" />;
        } else if (location.pathname == pageRoutes.reportUnableMappedPage) {
            return <ReportUnableMapped id="1" />;
        } else if (location.pathname == pageRoutes.reportSSToMGPage) {
            return <ReportOtherException id="1" />;
        } else if (location.pathname == pageRoutes.reportSSToMSPage) {
            return <ReportOtherException id="1" />;
        } else if (location.pathname == pageRoutes.reportSGToMGPage) {
            return <ReportOtherException id="1" />;
        } else if (location.pathname == pageRoutes.reportRecommendationPage) {
            return <ReportOtherException id="1" />;
        } else if (location.pathname == pageRoutes.reportAddedMappingPage) {
            return <ReportOtherException id="1" />;
        }
    };

    return (<>
        <Loader loading={loading} doNotOverFlow={true} />
        <div className="page-container-default">
            <div className="custom-container">
                <Navbar className="bungee-gaps-nav p-0">
                    <Nav className="me-auto">
                        {generateNavs()}
                    </Nav>
                </Navbar>
                <hr className='section-divider mb-4' />
                {renderReport()}
            </div>
        </div>
    </>);
}

export default Report;