import { apiCall } from "../../common/common";
import { decodeToken } from "react-jwt";
import {
    setLocalStorageValue,
    removeValueFromLocalStorage
  } from "../../../utils/localStorageOperations";

export const ssoLogin = async (tokens) => {
    const idTokenParse = decodeToken(tokens.idToken);
    const email = idTokenParse["email"]

    let userResponse = await apiCall("GET", "/match/user", {}, { user_id: email, is_login: true })
    if (userResponse?.data?.length === 0) {
        try {
            userResponse = await apiCall("POST", "/match/user", {}, { user_id: email, is_admin: false, is_login: true })
        } catch (error) {
            console.log(error)
            removeValueFromLocalStorage([
                "accessToken",
                "idToken",
                "expiryTime",
                "userData",
                "email",
                "isAdmin",
                "isCmtLeadAuditor"
            ])
        }
    }

    if (!userResponse || userResponse.status !== 200) {
        alert("TO DO SHOW BETTER ALERT")
    }
    const user = userResponse?.data[0]
    const userData = { 
        email,
        role: user?.is_admin ? "admin" : "client",
        ability: [
            {
                action:"manage",
                subject:"all"
            }
        ]
    }

    setLocalStorageValue("accessToken", tokens.accessToken)
    setLocalStorageValue("idToken", tokens.idToken)
    setLocalStorageValue("expiryTime", idTokenParse.exp)
    setLocalStorageValue("userData", JSON.stringify(userData))
    setLocalStorageValue("email", email)
    setLocalStorageValue("isAdmin", user?.is_admin)
    setLocalStorageValue("isCmtLeadAuditor", user?.is_cmt_lead_auditor)
    window.location.href = "/dashboard"
}