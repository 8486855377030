import React, { useEffect, useState, useContext } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import { addParamsToLink, hardApiCall, numberWithCommas, getSegmentLib, dateString } from "../common/common";
import { toast } from "react-toastify";
import Select from 'react-select';
import * as pageRoutes from "../../pageRoutes";
import Loader from "../common/Loader/Loader";
import SideMenuCommonFilterLib from "../../contextApi/SideMenuCommonFilterLib";
import SideMenuCommonFilter from "../../contextApi/SideMenuCommonFilter";
import moment from "moment";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const Summary = (props) => {

    const [sideMenuCommonFilterLibContext, setSideMenuCommonFilterLibContext] = useContext(SideMenuCommonFilterLib);
    const [sideMenuCommonFilterContext, setSideMenuCommonFilterContext] = useContext(SideMenuCommonFilter);
    //const [dateRange, setDateRange] = useState([moment(new Date().toISOString(), 'YYYY-MM-DDTHH:mm:ss'), moment(new Date().toISOString(), 'YYYY-MM-DDTHH:mm:ss')]);
    const [loading, setLoading] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);
    const [retailerList, setRetailerlist] = useState([]);
    const [currentSegment, setCurrentSegment] = useState(sideMenuCommonFilterContext.segment.values().next().value);
    const [currentRetailer, setCurrentRetailer] = useState(sideMenuCommonFilterContext.source_retailer.values().next().value);
    const [segmentLib, setSegmentLib] = useState(getSegmentLib());
    const [summaryObj, setSummaryObj] = useState({
        addedMapping: 0,
        approvedMapping: 0,
        exceptionItemsSGToMG: 0,
        exceptionItemsSSToMG: 0,
        exceptionItemsSSToMS: 0,
        notMapped: 0,
        pendingItems: 0,
        recommendation: 0,
        unableToMap: 0
    });

    const renderTooltip = (message, props) => (
        <Tooltip id="button-tooltip" {...props}>
            {message}
        </Tooltip>
    );

    const toolTipMessage = (message) => {
        return (<OverlayTrigger
            message={message}
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={e => renderTooltip(message, e)}
        >
            <span><FontAwesomeIcon className="fa-sm ms-2" icon={faInfoCircle} /></span>
        </OverlayTrigger>);
    };

    const applyfilter = (filter) => {
        if (currentRetailer) {
            filter['source_retailer'] = currentRetailer;
        }
        if (currentSegment) {
            filter['segment'] = currentSegment;
        }
        if (dateRange[0] && dateRange[1]) {
            filter['startDate'] = dateRange[0].format('YYYY-MM-DD HH:mm:ss');
            filter['endDate'] = dateRange[1].format('YYYY-MM-DD HH:mm:ss');
        }
        return filter;
    };

    const getSummary = () => {
        setLoading(true);
        let api = '/categoryMapping/reportSummary';
        let filter = {};
        filter = applyfilter(filter);
        hardApiCall("GET", api, {}, filter)
        .then((response) => {
            setLoading(false);
            if (typeof response.data == 'object') {
                let newSummaryObj = {...summaryObj};
                Object.keys(response.data).map(responseKey => {
                    newSummaryObj[responseKey] = response.data[responseKey];
                });
                setSummaryObj(newSummaryObj);
            } else {
                toast.error("There is an error in data response!");
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log("ERROR", error);
            toast.error("There is an error in data response!");
        });
    };

    useEffect(() => {
        getSummary();
        let newSideMenuCommonFilterContext = {...sideMenuCommonFilterContext};
        newSideMenuCommonFilterContext['source_retailer'] = new Set([currentRetailer]);
        newSideMenuCommonFilterContext['segment'] = new Set([currentSegment]);
        newSideMenuCommonFilterContext['date_range'] = new Set(dateRange);
        setSideMenuCommonFilterContext(newSideMenuCommonFilterContext);
    },[currentRetailer, currentSegment, dateRange]);

    useEffect(() => {
        if (sideMenuCommonFilterLibContext.retailers.length) {
            setRetailerlist(sideMenuCommonFilterLibContext.retailers);
        }
    },[sideMenuCommonFilterLibContext]);

    const dateRangeSelect = (start, end, label) => {
        if (start.isValid() && end.isValid()) {
            setDateRange([start, end]);
        } else {
            if (dateRange[0] != null && dateRange[1] != null) {
                setDateRange([null, null]);
            }
        }
    };

    const showSelectedDateRange = () => {
        let dateRangeString = "";
        if (dateRange[0] != null && dateRange[1] != null) {
            if (dateRange[0].format('YYYY-MM-DD') != dateRange[1].format('YYYY-MM-DD')) {
                dateRangeString = dateRange[0].format('YYYY-MM-DD') + " - " + dateRange[1].format('YYYY-MM-DD');
            } else {
                dateRangeString = dateRange[0].format('YYYY-MM-DD');
            }
        }
        return dateRangeString;
    };

    return (<>
        <Loader loading={loading} doNotOverFlow={true} />
        <Row className="bg-white p-4 mb-5">
            <Col>
                <div className="filter-name">
                    <div className="name-top">Select Retailer</div>
                    <Select 
                        onChange={e => {
                            if (e) {
                                setCurrentRetailer(e.value.toLowerCase());
                            } else {
                                setCurrentRetailer("");
                            }
                        }}
                        value={currentRetailer.length ? {
                            label: currentRetailer.toUpperCase(),
                            value: currentRetailer
                        } : null}
                        isClearable={true}
                        placeholder="Search Retailer"
                        options={retailerList.map(retailerListSingle => {
                            return {
                                label: retailerListSingle.toUpperCase(),
                                value: retailerListSingle.toLowerCase()
                            }
                        })}
                    />
                </div>
            </Col>
            <Col>
                <div className="filter-name">
                    <div className="name-top">Select Segment</div>
                    <Select 
                        onChange={e => {
                            if (e) {
                                setCurrentSegment(e.value.toLowerCase());
                            } else {
                                setCurrentSegment("");
                            }
                        }}
                        value={currentSegment.length ? {
                            label: segmentLib.find(segmentLibSingle => {
                                return segmentLibSingle.toLocaleLowerCase() == currentSegment;
                            }),
                            value: currentSegment
                        } : null}
                        isClearable={true}
                        placeholder="Search Source Segment"
                        options={segmentLib.map(segmentSingle => {
                            return {
                                label: segmentSingle,
                                value: segmentSingle.toLowerCase()
                            }
                        })}
                    />
                </div>   
            </Col>
            <Col sm="2">
                <div className="filter-name">
                    <DateRangePicker
                        initialSettings={{ 
                            startDate: dateRange[0], 
                            endDate: dateRange[1],
                            ranges: {
                                'All': [null, null],
                                'Today': [moment(), moment()],
                                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                'This Week': [moment().startOf('week').add(1, 'days'), moment().endOf('week').add(1, 'days')],
                                'Last Week': [moment().subtract(1, 'weeks').startOf('week').add(1, 'days'), moment().subtract(1, 'weeks').endOf('week').add(1, 'days')],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                'Last 90 Days': [moment().subtract(89, 'days'), moment()]
                            },
                            showCustomRangeLabel: false,
                        }}
                        onCallback={dateRangeSelect}
                    >
                        <button className="btn btn-sm btn-outline-secondary w-100"><FontAwesomeIcon icon={faCalendarAlt} /> {(!dateRange[0] && !dateRange[1])
                            ? "ALL" 
                            : ((dateRange[0].format('YYYY-MM-DD') == dateRange[1].format('YYYY-MM-DD')) 
                                ? dateRange[0].format('YYYY-MM-DD') 
                                : (dateRange[0].format('YYYY-MM-DD') + " - " + dateRange[1].format('YYYY-MM-DD')))} 
                        </button>
                    </DateRangePicker>
                </div>
            </Col>
        </Row>
        <div className="vh-100">
            <Row>
                <Col sm="2" className="bg-white p-4 m-2">
                    <div >
                        <h5>Not Mapped {toolTipMessage("The section consists of source retailers' hierarchies which are not mapped yet.")}</h5>
                        <Link to={addParamsToLink(pageRoutes.reportNotMappedPage)} className="text-decoration-none fs-1">{numberWithCommas(summaryObj.notMapped)}</Link>
                    </div>
                </Col>
                <Col sm="2" className="bg-white p-4 m-2">
                    <div >
                        <h5>In Review {toolTipMessage("This section consists of the mappings performed by the auditors (Added mapping and Removed mapping) that is pending review by the lead auditors.")}</h5>
                        <div className="fs-1" >
                            <Link to={addParamsToLink(pageRoutes.reportPendingActionPage)} className="text-decoration-none">{numberWithCommas(summaryObj.pendingItems)}</Link>
                        </div>
                        <small className="float-end">&nbsp;{showSelectedDateRange()}</small>
                    </div>
                </Col>
                <Col sm="2" className="bg-white p-4 m-2">
                    <div >
                        <h5>Unable to Map {toolTipMessage("This section consists of source retailer hierarchies that the users are unable to map either due to a) Non-Pet Category, b) No Bungee Taxonomy Available to Map, c) Irrelevant ML Recommendation or d) other reasons")}</h5>
                        <div className="fs-1" >
                            <Link to={addParamsToLink(pageRoutes.reportUnableMappedPage)} className="text-decoration-none">{numberWithCommas(summaryObj.unableToMap)}</Link>
                        </div>
                        <small className="float-end">&nbsp;{showSelectedDateRange()}</small>
                    </div>
                </Col>
                <Col sm="2" className="bg-white p-4 m-2">
                    <div >
                        <h5>Approved Mapping {toolTipMessage("This section consists of the mappings that has been reviewed and approved (Final) by the lead auditor. This will be the final state that can be consumed downstream.")}</h5>
                        <div className="fs-1" >{numberWithCommas(summaryObj.approvedMapping)}</div>
                        <small className="float-end">&nbsp;{showSelectedDateRange()}</small>
                    </div>
                </Col>
                <Col sm="2" className="bg-white p-4 m-2">
                    <div >
                        <h5>Recommendation {toolTipMessage("This section consists of the list of source retailer categories that has recommendations available for mapping.")}</h5>
                        <div className="fs-1" >{numberWithCommas(summaryObj.recommendation)}</div>
                    </div>
                </Col>
            </Row>
            <Row><Col sm="12" className="p-4"><h4>Other Exceptions</h4></Col></Row>
            <Row>
                <Col sm="3" >
                    <div className="bg-white p-4 m-2">
                        <h5>Single Specific to Multiple Generic {toolTipMessage("This section consists of the list of Specific hierarchy to multiple Generic hierarchies.")}</h5>
                        <Link to={addParamsToLink(pageRoutes.reportSSToMGPage)} className="text-decoration-none fs-1">{numberWithCommas(summaryObj.exceptionItemsSSToMG)}</Link>
                    </div>
                </Col>
                <Col sm="3">
                    <div className="bg-white p-4 m-2">
                        <h5>Single Specific to Multiple Specific {toolTipMessage("This section consists of the list of Specific hierarchy to multiple Specific hierarchies.")}</h5>
                        <Link to={addParamsToLink(pageRoutes.reportSSToMSPage)} className="text-decoration-none fs-1">{numberWithCommas(summaryObj.exceptionItemsSSToMS)}</Link>
                    </div>
                </Col>
                <Col sm="3">
                    <div className="bg-white p-4 m-2">
                        <h5>Single Generic to Multiple Generic {toolTipMessage("This section consists of the list of Generic hierarchy to multiple Generic hierarchies.")}</h5>
                        <Link to={addParamsToLink(pageRoutes.reportSGToMGPage)} className="text-decoration-none fs-1">{numberWithCommas(summaryObj.exceptionItemsSGToMG)}</Link>
                    </div>
                </Col>
            </Row>
        </div>
    </>);
};

export default Summary;