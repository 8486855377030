import { faArrowAltCircleLeft, faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Pagination } from "react-bootstrap";

const PaginationCustom = (props) => {
    const {
        pageinationArr,
        pageClicked,
        page
    } = props; 

    return (<>{pageinationArr.map((ele, ind) => {
        const toReturn = [];

        if (ind === 0) {
        /*toReturn.push(
            <Pagination.First
            key={"firstpage"}
            onClick={
                page === 1
                ? () => {}
                : () => {
                    pageClicked(1);
                    }
            }
            />
        );*/

        toReturn.push(
            <Pagination.Item
            className="prev me-2"
            key={"prevpage"}
            disabled={page === 1}
            onClick={
                page === 1
                ? () => {}
                : () => {
                    pageClicked(page - 1);
                }
            }><FontAwesomeIcon icon={faArrowAltCircleLeft} /></Pagination.Item>
        );
        }

        if (ele === "") {
            toReturn.push(<Pagination.Ellipsis className="border-less" disabled key={ind} />);
        } else {
            toReturn.push(
                <Pagination.Item
                key={ind}
                active={page === ele ? true : false}
                className="border-less"
                onClick={
                    page === ele
                    ? () => {}
                    : () => {
                        pageClicked(ele);
                    }
                }
                >
                {ele}
                </Pagination.Item>
            );
        }

        if (ind === pageinationArr.length - 1) {
        toReturn.push(
            <Pagination.Item
            className="next ms-2 me-2"
            key={"nextpage"}
            disabled={page === ele}
            onClick={
                page === ele
                ? () => {}
                : () => {
                    pageClicked(page + 1);
                }
            }
            ><FontAwesomeIcon icon={faArrowAltCircleRight} /></Pagination.Item>
        );

        /*toReturn.push(
            <Pagination.Last
            key={"lastpage"}
            onClick={
                page === ele
                ? () => {}
                : () => {
                    pageClicked(ele);
                }
            }
            />
        );*/
        }

        return toReturn;
    })}</>);
};
export default PaginationCustom;