import React, { useEffect, useState } from "react";
import SideBarMenu from "../SideBarMenu";
//utils
import {
  getQueryParams,
  addParamstoPage,
  removeParamstoPage,
} from "../../../utils/routeBasedOperations";
// context api
import SideMenuCommonFilter from "../../../contextApi/SideMenuCommonFilter";
import SideMenuCommonFilterLib  from "../../../contextApi/SideMenuCommonFilterLib";
import SideMenuCommonFilterDefault  from "../../../contextApi/SideMenuCommonFilterDefault";
import BreadCrumbsRoutes from "../../../contextApi/BreadCrumbsRoutes";

import "./layout.scss";
import EventCommonFilter from "../../../contextApi/EventCommonFilter";
import { hardApiCall } from "../common";

// const brand = getQueryParams("keyword");
const country = getQueryParams("country");
const brand = getQueryParams("brand");

const contextApiDefaultValue = {
  source_retailer: new Set([""]),
  segment: new Set([""]),
  source_cat: new Set([""]),
  source_subcat: new Set([""]),
  source_sub_subcat: new Set([""]),
  source_sub_sub_subcat: new Set([""]),
  source_tag: new Set([""]),
  date_range: new Set([null, null]),
};
const contextApiDefaultLibValue = {
  retailers: [],
  targetRetailers: ['bungee', 'chewy', 'bjs'],
};

const Layout = ({ children }) => {
  const [
    sideMenuCommonFilterContext,
    setSideMenuCommonFilterContext,
  ] = useState(contextApiDefaultValue);

  const [
    sideMenuCommonFilterDefaultContext,
    setSideMenuCommonFilterDefaultContext,
  ] = useState(contextApiDefaultValue);

  const [
    sideMenuCommonFilterLibContext,
    setSideMenuCommonFilterLibContext,
  ] = useState(contextApiDefaultLibValue);

  const [
    eventCommonFilterContext,
    eventMenuCommonFilterLibContext,
  ] = useState({});

  const stateUpdateFromContext = (state) => {
    let removeParams = ['kpi'];
    if (state?.retailer) {
      const retailerSelection = Array.from(state.retailer);
      if (retailerSelection.length == 0) {
        removeParams.push('retailer');
      } /*else {
        addParamstoPage({ retailer: retailerSelection });
      }*/
    }
    if (state?.category) {
      const categorySelection = Array.from(state.category);
      if (categorySelection.length == 0) {
        removeParams.push('category');
      } /*else {
        addParamstoPage({ category: categorySelection}); 
      }*/
    }
    removeParamstoPage(removeParams);
    setSideMenuCommonFilterContext(state);
  };

  const [BreadCrumbs, setBreadCrumbs] = useState([]);
  // fixMenuToOpen  - decides fixation of Menu
  // hideMenu - works for on hover events for menu while collapsed
  const [overlayMenu, setOverlayMenu] = useState(false);
  const [fixMenuToOpen, setFixMenuToOpen] = useState(false);

  const menuShowHideHandler = () => {
    setFixMenuToOpen(!fixMenuToOpen);
  };
  const arrowIconForToggle = !fixMenuToOpen
    ? "/images/icons/common/double_arrow_right.svg"
    : "/images/icons/common/double_arrow_left.svg";

  const mouseMoveEventHandler = (e) => {
    if (e.clientX === 0) {
      setOverlayMenu(true);
    }
  };

  const mouseOutEventHandler = (e) => {
    if (!e.target.closest("#sidebar-menu")) {
      setOverlayMenu(false);
    }
  };

  const getRetailers = () => {
    let api = '/categoryMapping/retailer';
    hardApiCall("GET", api, {}, {})
    .then((response) => {
        if (typeof response.data == 'object') {
            let retailers = response.data.filter(singleData => {
                return singleData.source_retailer != "bungee";
            }).map(singleData => {
                return singleData.source_retailer;
            });
            /*.sort((a, b) => {
              if (a < b) {
                return -1;
              }
              if (a > b) {
                return 1;
              }
              return 0;
            })*/
            let contextApiDefaultLibValueNew = {...contextApiDefaultLibValue};
            contextApiDefaultLibValueNew.retailers = retailers;
            setSideMenuCommonFilterLibContext(contextApiDefaultLibValueNew);
        } else {
            //toast.error("There is an error in data response!");
        }
    })
    .catch((error) => {
        console.log("ERROR", error);
        //toast.error("There is an error in data response!");
    });
  };

  const loadLib = () => {
    getRetailers();
  };

  useEffect(() => {
    loadLib();
    //window.addEventListener("mouseover", mouseMoveEventHandler);
    //window.addEventListener("mouseout", mouseOutEventHandler);
    return () => {
      //window.removeEventListener("mouseover", mouseMoveEventHandler);
      //window.addEventListener("mouseout", mouseOutEventHandler);
    };
  }, []);

  return (
    <BreadCrumbsRoutes.Provider value={[BreadCrumbs, setBreadCrumbs]}>
      <SideMenuCommonFilter.Provider
        value={[sideMenuCommonFilterContext, stateUpdateFromContext]}
      >
        <SideMenuCommonFilterDefault.Provider
          value={[sideMenuCommonFilterDefaultContext, setSideMenuCommonFilterDefaultContext]}
        >
          <SideMenuCommonFilterLib.Provider
            value={[sideMenuCommonFilterLibContext, setSideMenuCommonFilterLibContext]}
          >
            <EventCommonFilter.Provider
              value={[eventCommonFilterContext, eventMenuCommonFilterLibContext]}
            >
              <div id="page-layout">
                <SideBarMenu />
                {children}
              </div>
            </EventCommonFilter.Provider>
          </SideMenuCommonFilterLib.Provider>
        </SideMenuCommonFilterDefault.Provider>
      </SideMenuCommonFilter.Provider>
    </BreadCrumbsRoutes.Provider>
  );
};

export default Layout;
