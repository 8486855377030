/**
 * @description API endpoints
 * @example Steps to add a API endpoint
 * Step 1. `export const SAMPLE_URL = 'BASE_URL/endpoint'`
 * Step 2. Do not forget to add a inline comment on the top of the URL declaration
 */

export const loginApi = "/auth/login";
export const passResetApi = "/auth/password/reset";
export const confirmPassResetApi = "/auth/password/confirm";
export const tenantInfoApi = "/tenant/info";
export const refreshAuthTokenApi = "/auth/refreshToken";

export const filterOptionApi = "/autocomplete/load";
export const filtersApi = "/unifiedFilter";
export const pricingFilterApi = "/pricingFilter";

export const matchUser = "/match/user";
export const getNormalizedApi = "/brand/normalized-brand";
export const getHierarchyApi = "/brand/hierarchy";
export const getClassifiedApi = "/brand/classified-brand";

//reports baseUrl
//export const cmtBaseUrl = "https://fd32kovc81.execute-api.us-east-1.amazonaws.com/prod";
export const cmtBaseUrl = "http://localhost:4002";