import { matchPath } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

export const matchActivePath = (currentPath) => {
  return matchPath(window.location.pathname, currentPath)?.isExact;
};

export const getQueryParams = (param) => {
  const params = new URLSearchParams(window?.location?.search);
  let paramObj = {};
  if (param) {
    try {
      paramObj = params.get(param);
    } catch (err) {
      console.log(err);
    }
  } else {
    for (var value of params.keys()) {
      if (value !== undefined) {
        paramObj[value] = params.get(value);
      }
    }
  }
  return paramObj;
};

// newParam => {key: value}
export const addParamstoPage = (newParam) => {
  let paramDivider = "?";
  let params = "";
  const oldParams = getQueryParams() ? getQueryParams() : {};
  const queryParams = { ...oldParams, ...newParam };
  let paramObj = {};
  if (queryParams) {
    for (let id of Object.keys(queryParams)) {
      paramObj[id] = queryParams[id];

      params += paramObj[id] && paramDivider + id + "=" + paramObj[id];
      paramDivider = "&";
    }
  }

  window.history.pushState(null, null, params);
};

export const removeParamstoPage = (param) => {
  let paramDivider = "?";
  let params = "";
  const oldParams = getQueryParams() ? getQueryParams() : {};
  const queryParams = { ...oldParams };
  let paramObj = {};
  if (queryParams) {
    for (let id of Object.keys(queryParams)) {
      if (!param.includes(id)) {
        paramObj[id] = queryParams[id];
        params += paramObj[id] && paramDivider + id + "=" + paramObj[id];
        paramDivider = "&";
      }
    }
  }
  if (params.length == 0) {
    params += paramDivider
  }
  window.history.pushState(null, null, params);
};

// history - props.history can be fetched using withRouter as HOC
// path - string
// state - Object (key value pair)
// newParams - Object (key value pair)
export const redirectTo = (history, path, state = {}, newParams = {}) => {
  let params = "";
  const allParams = { ...getQueryParams(), ...newParams };
  let paramDivider = "?";
  for (let ele in allParams) {
    params += `${paramDivider}${ele}=${allParams[ele]}`;
    paramDivider = "&";
  }

  history.push({
    pathname: path,
    search: params,
    state,
  });
};

export const getStateFromHistory = (history, keyName) => {
  const state = history?.location?.state;
  return state && state[keyName];
};

export const generatePagination = (totalCount, limit, page) => {
  let totalPages = Math.ceil(totalCount/limit);
  let currentPage = page;
  let pageArr = [];
  if (totalPages > 1) {
      if (totalPages <= 3) {
          let i = 1;
          while (i <= totalPages) {
              pageArr.push(i);
              i++;
          }
      } else {
          if (currentPage <= 2) {
              pageArr = [1, 2, "", totalPages];
          } else if (totalPages - currentPage <= 1) {
              pageArr = [
                  1,
                  "",
                  totalPages - 1,
                  totalPages
                ];
          } else {
              pageArr = [
                  1,
                  "",
                  currentPage,
                  "",
                  totalPages
                ];
          }
      }
    }
    return (pageArr);
};

export const triggerFilterEvent = (current, updated) => {
  /*Object.keys(updated).map(updatedSingle => {
    let currentValue = Array.from(current[updatedSingle]);
    let updatedValue = Array.from(updated[updatedSingle]);
    if (currentValue.join(',') != updatedValue.join(',')) {
      if (updatedValue.length) {
        if (['best_seller', 'third_party', 'tenant_priority'].includes(updatedSingle) && updatedValue[0] == 'ALL') {
          return false;
        }
        if (['brand_gap', 'private_label', 'image_competitor'].includes(updatedSingle) && updatedValue[0] == false) {
          return false;
        }
        if (['assortment_score', 'economic_performance_score', 'customer_value_score', 'catalog_strategy_score'].includes(updatedSingle) && updatedValue[0] == 0 && updatedValue[1] == 100) {
          return false;
        }
        if (['rating'].includes(updatedSingle) && updatedValue[0] == 0 && updatedValue[1] == 5) {
          return false;
        }
        let newValue = updatedValue.filter(singleUpdatedValue => !currentValue.includes(singleUpdatedValue));
        if (newValue.length) {
          //console.log('filter', updatedSingle, currentValue, updatedValue, newValue);
          newValue.map(newSingleValue => {
            let newfiterEvent = {};
            newfiterEvent['filter_' + updatedSingle] = newSingleValue;
            console.log('filter', newfiterEvent);
            window.gtag("event", "filter", newfiterEvent);
          });
        }
      }
    }
  });*/
};

export const generateRefreshDate = (module = 'assortment') => {
  let staticLastRefreshDate = "2022-10-04";
  let staticNextRefreshDate;
  if (localStorage.getItem("lastRefreshDate")) {
    let lastRefreshedDateObj = JSON.parse(localStorage.getItem("lastRefreshDate"));
    Object.keys(lastRefreshedDateObj).map(lastRefreshedDateKey => {
      if (lastRefreshedDateKey.toLowerCase() == module) {
        staticLastRefreshDate = lastRefreshedDateObj[lastRefreshedDateKey];
      }
    });
  }

  if (localStorage.getItem("nextRefreshDate")) {
    let nextRefreshDateObj = JSON.parse(localStorage.getItem("nextRefreshDate"));
    Object.keys(nextRefreshDateObj).map(nextRefreshDateKey => {
      if (nextRefreshDateKey.toLowerCase() == module) {
        staticNextRefreshDate = nextRefreshDateObj?.[nextRefreshDateKey];
      }
    });
  }

  return (<Row>
    { staticNextRefreshDate ?
      (<><Col sm={4}>
      </Col>
      <Col sm={8}>
          <p className="float-end">Last Refresh Date: <strong>{staticLastRefreshDate}</strong></p>
          <p className="float-end me-4">Next Refresh Date: <strong>{staticNextRefreshDate}</strong></p>
        </Col></>) :
      (<><Col sm={6}>
        </Col>
        <Col sm={6}>
            <p className="float-end">&nbsp;</p>
        </Col></>)
    }
  </Row>);
}