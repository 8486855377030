import { decodeToken } from "react-jwt";
import axios from "axios";
import qs from "qs";
import {
  getLocalStorageValue,
  setLocalStorageValue,
  removeValueFromLocalStorage,
  setCompanyDateFromLocalStorage,
} from "./localStorageOperations";

const waitInLoop = (resolve) => {
  if (localStorage.getItem('checkSessionStatus') == null) {
    resolve(true);
  } else {
    setTimeout(function(){
      waitInLoop(resolve);
    }, 1000);
  }
};

export const checkSessionStatus = (idToken) => {
  const decodedObj = decodeToken(idToken);
  // time provided by Auth is in epoch (second)
  // while js uses milliseconds, hence * 1000
//   console.log('decodedObj', decodedObj);
  const expirationTime = decodedObj?.exp * 1000;
  const refreshToken = getLocalStorageValue("refreshToken");
  const email = decodedObj?.email;
  const currentTime = Date.now();
  const refreshTime = 1 * 60 * 1000; // min * seconds * millseconds
//   console.log('countdown', (expirationTime - (currentTime + refreshTime)));

  return new Promise((resolve) => {
    // refresh time is used to refresh auth token
    // before idToken expires
    if (!idToken || idToken === undefined || idToken === "undefined") {
      resolve(false);
    }
    if (expirationTime < (currentTime + refreshTime)) {
      /*removeValueFromLocalStorage([
        "accessToken",
        "idToken",
        "expiryTime",
        "userData",
        "email",
        "isAdmin"
      ]);
      resolve(false);*/
      resolve(true);
      /*if (localStorage.getItem('checkSessionStatus')) {
        waitInLoop(resolve);
      } else {
        localStorage.setItem('checkSessionStatus', '1');
        console.log('setcheckSessionStatusstorage');
        axios({
          method: "GET",
          url: base_url + refreshAuthTokenApi,
          headers: {
            "Authorization": "Bearer " + refreshToken,
            "X-Bungee-Tenant": decodedObj ? decodedObj["custom:tenant"] : null
          },
          credentials: "omit",
          data: qs.stringify({ email, refreshToken }),
        })
          .then((response) => {
            console.log('response.status', response.status);
            if (response.status === 200) {
              setLocalStorageValue("accessToken", response.data.data.AccessToken);
              setLocalStorageValue("idToken", response.data.data.IdToken);
            } else {
              alert("Some error ocurred");
            }
            localStorage.removeItem('checkSessionStatus');
            resolve(true);
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.status == 500) {
              removeValueFromLocalStorage([
                "accessToken",
                "idToken",
                "expiryTime",
                "userData",
                "email",
                "isAdmin"
              ]);
              resolve(false);
            }
            localStorage.removeItem('checkSessionStatus');
          });
      }*/

    } else {
      resolve(true);
    }
  });
};
