import React from "react";
import Skeleton from "react-loading-skeleton";

const PageRoutingSkeleton = () => {
  return (
    <div className="page-container-default mt-5" >
      <Skeleton width="100" height="100px" />
      <Skeleton width="100" height="100px" />
      <Skeleton width="100" height="100px" />
      <Skeleton width="100" height="100px" />
      <Skeleton width="100" height="100px" />
    </div>
  );
};

export default PageRoutingSkeleton;
