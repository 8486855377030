import axios from "axios";
import { loginApi } from "./constants.js";
import { decodeToken } from "react-jwt";
import * as pageRoutes from "../../pageRoutes.js";
import qs from "qs";
// import {base_url} from "./constants.js";
import { checkSessionStatus } from "../../utils/loginSessionOperations";
import { getQueryParams } from "../../utils/routeBasedOperations";
import {
  getLocalStorageValue, removeValueFromLocalStorage,
} from "../../utils/localStorageOperations";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config/config.js";

const redirectPageToLogin = (authFailed) => {
  const idToken = localStorage.getItem("idToken");
  if (!idToken || authFailed) {
    window.location.href = pageRoutes.loginPage + "?error=true";
  }
};

export const commonApiCall = async (method, endpoint, headers = {}, data = {}) => {
    return new Promise(async (resolve, reject) => {
        console.log(process.env.REACT_APP_NODE_ENV)
        const urlEndpoint = `${config.backendEndpoint}/common/${endpoint}`;
        const headersWithToken = {
            ...headers,
            ...{
                "Content-Type": "application/json"
            }
        }
        
        const axiosOptions = {
            method,
            url: urlEndpoint,
            headers: headersWithToken,
            credentials: "omit"
        }
        
        if (method.toUpperCase() === "GET") {
            axiosOptions["params"] = data
        } else {
            axiosOptions["data"] = data
        }
        
        await axios(axiosOptions).then((response) => {
            if (response.data === "Invalid token") {
                return resolve(false)
            } else {
                resolve(response);
            }
        }).catch((error) => {
            if (error?.response?.status === 401) {
                resolve(error.response)
            } else {
                reject(error)
            }
        })
    });
}

export const hardApiCall = async (method, url, headers = {}, data = {}) => {
  const newUrl = config.backendEndpoint + url;
  let idToken = localStorage.getItem("idToken");
  const tenant = localStorage.getItem("tenant");
  const loginPageCheck = (window.location.pathname !== pageRoutes.loginPage && window.location.pathname !== pageRoutes.signUpPage);
  let waitForSessionCheck = true;
  if (url != loginApi) {
    waitForSessionCheck = await checkSessionStatus(idToken).then(
      (response) => response
    );
  }
  const checkAuthToken = idToken && waitForSessionCheck;
  if (!checkAuthToken && loginPageCheck) {
    window.location.href = pageRoutes.loginPage + "?error=true";
  }
  idToken = localStorage.getItem("idToken");
  let decodedObj = decodeToken(idToken);
  let accessToken = localStorage.getItem("accessToken");
  const newHeaders = {
    ...headers,
    ...{
      "Content-Type": "application/json", //"application/json",
      "idtoken": idToken,
      //"Authorization": "Bearer " + accessToken,
      "X-Bungee-Tenant": tenant ? tenant : null
    },
  };
  let axiosOption = {
    method,
    url: newUrl,
    headers: newHeaders,
    // mode: "no-cors",
    credentials: "omit",
    data: qs.stringify(data),
  };
  if (method.toLowerCase() == 'get') {
    //console.log('data parems', data);
    axiosOption['params'] = data;
  } else {
    //axiosOption['data'] = qs.stringify(data)
    axiosOption['data'] = data;
  }
  return axios(axiosOption).then((response) => {
    if (response.data === "Invalid token") {
      redirectPageToLogin(true);
      return false;
    }
    return new Promise((resolve, reject) => {
      resolve(response);
    });
  }).catch(error => {
    if (error.response.status == 401) {
      removeValueFromLocalStorage([
        "accessToken",
        "idToken",
        "expiryTime",
        "userData",
        "email",
        "isAdmin",
        "isCmtLeadAuditor"
      ]);
      redirectPageToLogin(true);
      return false;
    }
  });;
};

// axios is seperately being used in src/utils/loginSessionOperations.js
export const apiCall = async (method, url, headers = {}, data = {}) => {
  const newUrl = config.backendEndpoint + url;
  let idToken = localStorage.getItem("idToken");
  const loginPageCheck = (window.location.pathname !== pageRoutes.loginPage && window.location.pathname !== pageRoutes.signUpPage);
  let waitForSessionCheck = true;
  if (url != loginApi) {
    waitForSessionCheck = await checkSessionStatus(idToken).then(
      (response) => response
    );
  }
  const checkAuthToken = idToken && waitForSessionCheck;
  if (!checkAuthToken && loginPageCheck) {
    window.location.href = pageRoutes.loginPage + "?error=true";
  }
  idToken = localStorage.getItem("idToken");
  let decodedObj = decodeToken(idToken);
  let accessToken = localStorage.getItem("accessToken");
  const newHeaders = {
    ...headers,
    ...{
      "Content-Type": "application/json", //"application/json",
      Authorization: getLocalStorageValue("idToken")
    },
  };

  //if (loginPageCheck) {
  //data.idtoken = idToken;
  //redirectPageToLogin();
  //}
  let axiosOption = {
    method,
    url: newUrl,
    headers: newHeaders,
    // mode: "no-cors",
    credentials: "omit",
    data: qs.stringify(data),
  };
  data.user_id = data.user_id || getLocalStorageValue("email");
  if (method.toLowerCase() == 'get') {
    //console.log('data parems', data);
    axiosOption['params'] = data;
  } else {
    //axiosOption['data'] = qs.stringify(data)
    axiosOption['data'] = data;
  }
  return axios(axiosOption).then((response) => {
    if (response.data === "Invalid token") {
      redirectPageToLogin(true);
      return false;
    }
    return new Promise((resolve, reject) => {
      resolve(response);
    });
  });
};

export const numberWithCommas = (x) => {
  if (x == null) {
    x = "";
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getSegmentLib = () => {
  return ["Pets", "Grocery", "Office Supplies"];
};

export const generatePaginationString = (totalCount, limit, page) => {
  let limitString = limit*page;
  if(parseInt(totalCount) < parseInt(limitString))
  {
    limitString = totalCount
  } 
  if (totalCount != 0) {
    return `${(limit*(page-1)) + 1} - ${limitString} Of ${numberWithCommas(totalCount)} Result`;
  }
}

const endcodString = (string) => {
  let endcString = string.replace("&", "%26");
  return endcString;
};

export const addParamsToLink = (to, params = {}) => {
  let newUrl = to;
  let paramDivider = "?";
  let allParams = getQueryParams();
  allParams = {...allParams, ...params};

  for (var ele in allParams) {
    newUrl += `${paramDivider}${ele}=${endcodString(allParams[ele])}`;
    paramDivider = "&";
  }
  // console.log(newUrl, "param");
  return newUrl ? newUrl : "";
};


export const getCustomLabel = (labelName) => {
  const filterConfig = JSON.parse(localStorage.getItem("tenantFilterConfig"));
  const customLabel = filterConfig?.[`${labelName.toLowerCase().replace(/tenant|_| /g, "")}_filter`];
  return customLabel ? customLabel : labelName;
};

export const getShortLabel = (labelName) => {
  let shotString = labelName;
  /*if (shotString.length > 9) {
    if (["estimated rev.", "3rd party present"].includes(shotString.toLowerCase())) {
      return shotString;
    }
    shotString = shotString.slice(0, 9);
    return shotString + "...";
  }*/
  return shotString;
};

export const getDownloadThresold = () => {
  let downloadThresold = localStorage.getItem("downloadThresold");
  downloadThresold = downloadThresold !== 'undefined' ? parseInt(downloadThresold) : 0;
  return downloadThresold;
}

export const checkSuppressionFlag = (row) => {
  let flagHtml = "";
  if (row.isSuppressionApplied) {
    flagHtml = <><div className="clearfix"></div><div className="badge bg-warning float-start">Suppression Processing</div></>;
  }
  return flagHtml;
}

export const checkUnSuppressionFlag = (row) => {
  let flagHtml = "";
  if (row.is_suppression_active == false) {
    flagHtml = <><div className="clearfix"></div><div className="badge bg-warning float-start">Unsuppression Processing</div></>;
  }
  return flagHtml;
}

export const dateString = (string) => {
  let date = string.toString();
  date = date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8);
  return date;
};

export const handlePriceRange = (minPrice, maxPrice) => {
  if (minPrice === 'NA' && maxPrice === 'NA') {
    return "NA";
  }
  else if (minPrice === 'NA' && maxPrice !== 'NA') {
    return "$" + maxPrice;
  }
  else if (minPrice !== 'NA' && maxPrice === 'NA') {
    return "$" + minPrice;
  }
  else {
    return "$" + minPrice + " - " + maxPrice;
  }
}

export const handleUpdatedBy = (updatedBy) => {
  return updatedBy === 'EMPTY' ? "" : updatedBy;
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const isOnlyDigits = (val) => {
  return /^\d+$/.test(val);
}
