import React, { useEffect, useState, useContext } from "react";
import { Col, Form, Row, Table, Button, InputGroup, Pagination } from "react-bootstrap";
import Select from 'react-select';
import { getSegmentLib, hardApiCall, generatePaginationString } from "../common/common";
import { toast } from "react-toastify";
import Loader from "../common/Loader/Loader";
import PaginationCustom from "../common/PaginationCustom/PaginationCustom";
import { generatePagination } from "../../utils/routeBasedOperations";
import SideMenuCommonFilter from "../../contextApi/SideMenuCommonFilter";
import ReportOtherExceptionRightSection from "./ReportOtherExceptionRightSection";
import SideMenuCommonFilterLib from "../../contextApi/SideMenuCommonFilterLib";
import moment from "moment";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

const ReportOtherExceptionSGToMG = () => {
    
    const [loading, setLoading] = useState(false);
    const [sideMenuCommonFilterContext, setSideMenuCommonFilterContext] = useContext(SideMenuCommonFilter);
    const [sideMenuCommonFilterLibContext, setSideMenuCommonFilterLibContext] = useContext(SideMenuCommonFilterLib);
    const [retailerList, setRetailerlist] = useState([]);
    const [hierarchyList, setHierarchyList] = useState([]);
    const [currentSegment, setCurrentSegment] = useState(sideMenuCommonFilterContext.segment.values().next().value);
    const [currentRetailer, setCurrentRetailer] = useState(sideMenuCommonFilterContext.source_retailer.values().next().value);
    const  date_range = [...sideMenuCommonFilterContext.date_range];
    const [dateRange, setDateRange] = useState((date_range.length == 2) ? date_range : [date_range[0], date_range[0]]);
    const [currentLeftSide, setCurrentLeftSide] = useState("");
    const [segmentLib, setSegmentLib] = useState(getSegmentLib());
    const [data, setData] = useState([]);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageinationArr, setPageinationArr] = useState([]);

    const getHierarchy = () => {
        setLoading(true);
        let api = '/categoryMapping/hierarchy';
        hardApiCall("GET", api, {}, {
            source_retailer: currentRetailer,
            segment: currentSegment.toLowerCase()
        })
        .then((response) => {
            setLoading(false);
            if (typeof response.data == 'object') {
                setHierarchyList(response.data);
            } else {
                toast.error("There is an error in data response!");
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log("ERROR", error);
            toast.error("There is an error in data response!");
        });
    };

    const applyfilter = (filter) => {
        filter['segment'] = currentSegment;
        filter['source_retailer'] = currentRetailer;
        filter['target_retailer'] = "bungee";
        filter['source_tag'] = "generic";
        filter['target_tag'] = "generic";
        if (dateRange[0] && dateRange[1]) {
            filter['startDate'] = dateRange[0].format('YYYY-MM-DD HH:mm:ss');
            filter['endDate'] = dateRange[1].format('YYYY-MM-DD HH:mm:ss');
        }
        return filter;
    };

    const getCount = () => {
        setLoading(true);
        let api = '/categoryMapping/exceptionItems';
        let filter = {
            limit: 0,
            page: 0,
            count: true
        };
        filter = applyfilter(filter);
        hardApiCall("GET", api, {}, filter)
        .then((response) => {
            setLoading(false);
            if (typeof response.data == 'object') {
                if (response.data.length) {
                    setCount(parseInt(response.data[0]['count']));
                } else {
                    setCount(0);
                }
            } else {
                toast.error("There is an error in data response!");
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log("ERROR", error);
            toast.error("There is an error in data response!");
        });
    };

    const getdata = () => {
        setLoading(true);
        let api = '/categoryMapping/exceptionItems';
        let filter = {
            limit: limit,
            page: page,
            count: false,
        };
        filter = applyfilter(filter);
        hardApiCall("GET", api, {}, filter)
        .then((response) => {
            setLoading(false);
            if (typeof response.data == 'object') {
                let leftSide = {};
                response.data.map(dataSingle => {
                    let leftKey = `${dataSingle.source_cat}|${dataSingle.source_subcat}|${dataSingle.source_sub_subcat}|${dataSingle.source_sub_sub_subcat}`;
                    if (!(leftKey in leftSide)) {
                        leftSide[leftKey] = {
                            left: dataSingle,
                            right: []
                        };
                    }
                    //leftSide[leftKey]['right'].push(dataSingle);
                });
                setData(Object.values(leftSide));
            } else {
                toast.error("There is an error in data response!");
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log("ERROR", error);
            toast.error("There is an error in data response!");
        });
    };

    useEffect(() => {
        getCount();
        let newSideMenuCommonFilterContext = {...sideMenuCommonFilterContext};
        newSideMenuCommonFilterContext['source_retailer'] = new Set([currentRetailer]);
        newSideMenuCommonFilterContext['segment'] = new Set([currentSegment]);
        setSideMenuCommonFilterContext(newSideMenuCommonFilterContext);
    },[currentRetailer, currentSegment, dateRange]);

    useEffect(() => {
        if (sideMenuCommonFilterLibContext.retailers.length) {
            setRetailerlist(sideMenuCommonFilterLibContext.retailers);
            /*if (sideMenuCommonFilterContext.source_retailer.values().next().value) {
                setCurrentRetailer(sideMenuCommonFilterContext.source_retailer.values().next().value);
            } else {
                setCurrentRetailer(sideMenuCommonFilterLibContext.retailers[0]);
            }*/
        }
    },[sideMenuCommonFilterLibContext]);

    useEffect(() => {
        getdata();
    }, [page, currentRetailer, currentSegment, dateRange]);

    useEffect(() => {
        let pageinationArrNew = generatePagination(count, limit, page);
        setPageinationArr(pageinationArrNew);
    }, [page, count]);

    const renderPagination = () => {
        return (<Pagination className="mt-3 float-end">
            <InputGroup>
                <Button disabled variant="light" className="me-2">{generatePaginationString(count, limit, page)} </Button>
                <PaginationCustom
                    pageinationArr={pageinationArr}
                    pageClicked={pageClicked}
                    page={page}
                />
            </InputGroup>
        </Pagination>);
    };

    const pageClicked = (pageNo) => {
        setPage(pageNo);
    };

    useEffect(() => {
        if (currentRetailer.length && currentSegment.length) {
            getHierarchy();
        } else {
            setHierarchyList([]);
        }
    },[currentRetailer, currentSegment]);

    const dateRangeSelect = (start, end, label) => {
        if (start.isValid() && end.isValid()) {
            setDateRange([start, end]);
        } else {
            if (dateRange[0] != null && dateRange[1] != null) {
                setDateRange([null, null]);
            }
        }
    };

    return (<>
        <Loader loading={loading} doNotOverFlow={true} />
        <Row className="">
            <Col sm="6" className="pe-1">
                <div className="bg-white p-3 pt-4 ">
                    <div className="filter-header">Source</div>
                    <Row className="mt-4">
                        <Col>
                            <div className="filter-name">
                                <div className="name-top">Select Retailer</div>
                                <Select 
                                    onChange={e => {
                                        if (e) {
                                            setCurrentRetailer(e.value.toLowerCase());
                                            let newSideMenuCommonFilterContext = {...sideMenuCommonFilterContext};
                                            newSideMenuCommonFilterContext['source_retailer'] = new Set([e.value.toLowerCase()]);
                                            setSideMenuCommonFilterContext(newSideMenuCommonFilterContext);
                                        }
                                    }}
                                    value={currentRetailer.length ? {
                                        label: currentRetailer.toUpperCase(),
                                        value: currentRetailer
                                    } : null}
                                    placeholder="Search Retailer"
                                    isClearable={true}
                                    options={retailerList.map(retailerListSingle => {
                                        return {
                                            label: retailerListSingle.toUpperCase(),
                                            value: retailerListSingle.toLowerCase()
                                        }
                                    })}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="filter-name">
                                <div className="name-top">Select Segment</div>
                                <Select 
                                    onChange={e => {
                                        if (e) {
                                            setCurrentSegment(e.value.toLowerCase());
                                        } else {
                                            setCurrentSegment("");
                                        }
                                    }}
                                    value={currentSegment.length ? {
                                        label: segmentLib.find(segmentLibSingle => {
                                            return segmentLibSingle.toLocaleLowerCase() == currentSegment;
                                        }),
                                        value: currentSegment
                                    } : null}
                                    placeholder="Search Source Segment"
                                    isClearable={true}
                                    options={segmentLib.map(segmentSingle => {
                                        return {
                                            label: segmentSingle,
                                            value: segmentSingle
                                        }
                                    })}
                                />
                            </div>
                        </Col>
                        {(currentRetailer.length && currentSegment.length && currentLeftSide.length) ? <Col>
                            <div className="filter-name">
                                <div className="name-top">Select Hierarchy</div>
                                <Select 
                                        isClearable={true}
                                        placeholder="Search Hierarchy"
                                        options={hierarchyList.map(hierarchySingle => {
                                            let curentLabel = hierarchySingle.source_cat.toUpperCase();
                                            curentLabel += " | " + hierarchySingle.source_subcat.toUpperCase();
                                            if (hierarchySingle.source_sub_subcat) {
                                                curentLabel += " | " + hierarchySingle.source_sub_subcat.toUpperCase();
                                            }
                                            if (hierarchySingle.source_sub_sub_subcat) {
                                                curentLabel += " | " + hierarchySingle.source_sub_sub_subcat.toUpperCase();
                                            }
                                            return {
                                                label: curentLabel,
                                                value: curentLabel
                                            }
                                        })}
                                        value={currentLeftSide.length ? {
                                            label: currentLeftSide.toUpperCase(),
                                            value: currentLeftSide.toUpperCase()
                                        } : null}
                                        onChange={e => {
                                            if (e) {
                                                setCurrentLeftSide(e.value.toLowerCase());
                                            } else {
                                                setCurrentLeftSide("");
                                            } 
                                        }}
                                    />
                            </div>
                        </Col> : ""}
                    </Row>
                </div>
            </Col>
            <Col sm="6" className="ps-1">
                <div className="bg-white p-3 pt-4">
                    <div className="filter-header">Target</div>
                    <Row className="mt-4">
                        <Col>
                            <div className="filter-name">
                                <div className="name-top">Select Retailer</div>
                                <Form.Select size="sm">
                                    <option>BUNGEE</option>
                                </Form.Select>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
        {data.length ? data.map(singleData => {
            let left = singleData.left;
            let right = singleData.right;
            return (<>
                <Row><Col sm="12"><hr className="separator-hr"/></Col></Row>
                <Row>
                    <Col sm="6" className="row-sections pe-1">
                        <div className="bg-white p-3">
                            <Table borderless size="sm">
                                <thead>
                                    <tr>
                                        <th>Retailer</th>
                                        <th>Segment</th>
                                        <th>Cat</th>
                                        <th>Sub Cat</th>
                                        <th>Sub Sub Cat</th>
                                        <th>Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{left.source_retailer}</td>
                                        <td>{left.segment}</td>
                                        <td>{left.source_cat.toLowerCase()}</td>
                                        <td>{left.source_subcat.toLowerCase()}</td>
                                        <td>{(left.source_sub_subcat ? left.source_sub_subcat.toLowerCase() : "") + (left.source_sub_sub_subcat ? (" | " + left.source_sub_sub_subcat.toLowerCase()) : "")}</td>
                                        <td>{left.source_tag}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                    <Col sm="6" className="row-sections ps-1">
                        <div className="bg-white p-3">
                            <ReportOtherExceptionRightSection key={JSON.stringify(left)} left={left} />
                        </div>
                    </Col>
                </Row>
            </>);
        }) : <>
        <Row>
            <Col sm="12"><hr className="separator-hr"/></Col>
        </Row>
        <Row className="bg-white pe-5 p-3 pt-4 m-0">
            <Col sm="12">No Data</Col>
        </Row>
        </>}
        <Row>
            <Col sm="12"><hr className="separator-hr"/></Col>
            <Col sm="12" >{renderPagination()}</Col>
        </Row>
    </>);
};

export default ReportOtherExceptionSGToMG;