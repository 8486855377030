import React, { useEffect, useState, useContext } from "react";
import { Button, ButtonGroup, Col, Form, InputGroup, Modal, Nav, NavDropdown, Navbar, OverlayTrigger, Popover, Row, Table } from "react-bootstrap";
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faWindowClose, faPlus, faCheck, faHourglassHalf, faComments, faPlayCircle, faStepBackward, faBackspace, faPlusCircle, faHourglass, faHourglassStart } from "@fortawesome/free-solid-svg-icons";
import { hardApiCall, getSegmentLib } from "../common/common";
import { toast } from 'react-toastify';
import Loader from "../common/Loader";

const MapRow = (props) => {
    const {
        hierarchySingle,
        currentSegment,
        currentRetailer,
        targetRetailer,
        bungeeHierarchyList
    } = props;

    const [hierarchySingleData, setHierarchySingleData] = useState(JSON.parse(JSON.stringify(hierarchySingle)));
    const [loading, setLoading] = useState(false);
    const [segmentLib, setSegmentLib] = useState(getSegmentLib());
    const [mappings, setMappings] = useState([]);
    const [newMappings, setNewMappings] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionsActual, setSuggestionsActual] = useState([]);
    const [newHierarchy, setNewHierarchy] = useState("");
    const [newTag, setNewTag] = useState("");
    const [notes, setNotes] = useState("");
    const [reasonCode, setReasonCode] = useState("");
    const [showOverlay, setShowOverlay] = useState(false);
    const [showUnableToMapReason, setShowUnableToMapReason] = useState(false);
    const isCmtLeadAuditor = (localStorage.getItem("isCmtLeadAuditor") == "true");
    //const isCmtLeadAuditor = true;

    const addNewHierarchy = () => {
        let newMappingsArr = [...newMappings];
        let newHierarchyArr = newHierarchy.split(" | ");
        newMappingsArr.push({
            mapping_source: "human",
            mapping_status: isCmtLeadAuditor ? "approved_mapping" : "added_mapping",
            segment: currentSegment,
            source_tag: hierarchySingleData.source_tag,
            source_retailer: currentRetailer,
            source_cat: hierarchySingleData.source_cat,
            source_subcat: hierarchySingleData.source_subcat,
            source_sub_subcat: hierarchySingleData.source_sub_subcat,
            source_sub_sub_subcat: hierarchySingleData.source_sub_sub_subcat,
            target_tag: newTag,
            target_retailer: targetRetailer,
            target_cat: newHierarchyArr[0] ? newHierarchyArr[0] : "",
            target_subcat: newHierarchyArr[1] ? newHierarchyArr[1] : "",
            target_sub_subcat: newHierarchyArr[2] ? newHierarchyArr[2] : "",
            target_sub_sub_subcat: newHierarchyArr[3] ? newHierarchyArr[3] : "",
        });
        setNewMappings(newMappingsArr);
        setNewHierarchy("");
        setNewTag("");
        setShowOverlay(false);
    };

    const getMappings = () => {
        setLoading(true);
        let api = '/categoryMapping/mapping';
        hardApiCall("GET", api, {}, {
            "segment" : currentSegment.toLowerCase(),
            "source_retailer" : currentRetailer,
            "target_retailer" : targetRetailer,
            "source_tag": hierarchySingle.source_tag,
            "source_cat" : hierarchySingle.source_cat,
            "source_subcat" : hierarchySingle.source_subcat,
            "source_sub_subcat" : hierarchySingle.source_sub_subcat,
            "source_sub_sub_subcat" : hierarchySingle.source_sub_sub_subcat,
        })
        .then((response) => {
            setLoading(false);
            if (typeof response.data == 'object') {
                setMappings(JSON.parse(JSON.stringify(response.data)));
                let newMappings = [...response.data].filter(meppListRightSingle => {
                    let valid = true;
                    if (meppListRightSingle.mapping_status == 'ready_for_mapping') {
                        valid = false;
                    }
                    return valid;
                });
                setNewMappings(JSON.parse(JSON.stringify(newMappings)));
            } else {
                toast.error("There is an error in data response!");
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log("ERROR", error);
            toast.error("There is an error in data response!");
        });
    };

    const getSuggestions = () => {
        setLoading(true);
        let api = '/categoryMapping/suggestion';
        hardApiCall("GET", api, {}, {
            "segment" : currentSegment.toLowerCase(),
            "source_retailer" : currentRetailer,
            "target_retailer" : targetRetailer,
            "source_tag": hierarchySingle.source_tag,
            "source_cat" : hierarchySingle.source_cat,
            "source_subcat" : hierarchySingle.source_subcat,
            "source_sub_subcat" : hierarchySingle.source_sub_subcat,
            "source_sub_sub_subcat" : hierarchySingle.source_sub_sub_subcat,
        })
        .then((response) => {
            setLoading(false);
            if (typeof response.data == 'object') {
                setSuggestions(response.data);
            } else {
                toast.error("There is an error in data response!");
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log("ERROR", error);
            toast.error("There is an error in data response!");
        });
    };

    const generateLeftSide = () => {
        return (<div className="bg-white p-3">
            <Table borderless size="sm">
                <thead>
                    <tr>
                        <th>Segment</th>
                        <th>Cat</th>
                        <th>Sub Cat</th>
                        <th>Sub Sub Cat</th>
                        <th>Type</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{currentSegment}</td>
                        <td>{hierarchySingleData.source_cat.toLowerCase()}</td>
                        <td>{hierarchySingleData.source_subcat.toLowerCase()}</td>
                        <td>{(hierarchySingleData.source_sub_subcat ? hierarchySingleData.source_sub_subcat.toLowerCase() : "") + (hierarchySingleData.source_sub_sub_subcat ? (" | " + hierarchySingleData.source_sub_sub_subcat.toLowerCase()) : "")}</td>
                        <td>
                            <Form.Select size="sm"
                                value={hierarchySingleData.source_tag ? hierarchySingleData.source_tag : ""}
                                onChange={e => {
                                    let newHierarchySingleData = {...hierarchySingleData};
                                    newHierarchySingleData.source_tag = e.target.value;
                                    setHierarchySingleData(newHierarchySingleData);
                                }}
                            >
                                {hierarchySingleData.source_tag ? null : <option value="">-</option>}
                                <option value="generic">Generic</option>
                                <option value="specific">Specific</option>
                            </Form.Select>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>);
    };

    const popoverAddClick = (
        <Popover id="popover-add-click" title="Popover bottom">
            <Popover.Header as="h3">Add New Mapping</Popover.Header>
            <Popover.Body>
                <Row>
                    <Col sm="12">
                        <div className="filter-name mt-2">
                            Segment: {currentSegment}
                        </div>
                    </Col>
                    <Col sm="12">
                        <div className="filter-name mt-4">
                            <div className="name-top">Hierarchy</div>
                            <Select 
                                isClearable={true}
                                hideSelectedOptions={true}
                                placeholder="Search Target Hierarchy"
                                options={bungeeHierarchyList.filter(bungeeHierarchySingle => {
                                    let valid = true;
                                    let mainHierarchy = `${bungeeHierarchySingle.source_cat.toLowerCase()} | ${bungeeHierarchySingle.source_subcat.toLowerCase()}`;
                                    if (bungeeHierarchySingle.source_sub_subcat) {
                                        mainHierarchy += ` | ${bungeeHierarchySingle.source_sub_subcat.toLowerCase()}`;
                                    }
                                    if (bungeeHierarchySingle.source_sub_sub_subcat) {
                                        mainHierarchy += ` | ${bungeeHierarchySingle.source_sub_sub_subcat.toLowerCase()}`;
                                    }
                                    let newHierarchies = newMappings.map(meppListRightSingle => {
                                        let newHierarchy = "";
                                        if (meppListRightSingle.target_cat && meppListRightSingle.target_subcat) {
                                            newHierarchy += `${meppListRightSingle.target_cat.toLowerCase()} | ${meppListRightSingle.target_subcat.toLowerCase()}`;
                                        }
                                        if (meppListRightSingle.target_sub_subcat) {
                                            newHierarchy += ` | ${meppListRightSingle.target_sub_subcat.toLowerCase()}`;
                                        }
                                        if (meppListRightSingle.target_sub_sub_subcat) {
                                            newHierarchy += ` | ${meppListRightSingle.target_sub_sub_subcat.toLowerCase()}`;
                                        }
                                        return newHierarchy;
                                    });
                                    if (newHierarchies.includes(mainHierarchy)) {
                                        valid = false;
                                    }
                                    return valid;
                                }).map(bungeeHierarchySingle => {
                                    let curentLabel = bungeeHierarchySingle.source_cat.toUpperCase();
                                    curentLabel += " | " + bungeeHierarchySingle.source_subcat.toUpperCase();
                                    if (bungeeHierarchySingle.source_sub_subcat) {
                                        curentLabel += " | " + bungeeHierarchySingle.source_sub_subcat.toUpperCase();
                                    }
                                    if (bungeeHierarchySingle.source_sub_sub_subcat) {
                                        curentLabel += " | " + bungeeHierarchySingle.source_sub_sub_subcat.toUpperCase();
                                    }
                                    return {
                                        label: curentLabel,
                                        value: curentLabel
                                    }
                                })}
                                value={newHierarchy.length ? {
                                    label: newHierarchy.toUpperCase(),
                                    value: newHierarchy.toUpperCase()
                                } : null}
                                onChange={e => {
                                    if (e) {
                                        setNewHierarchy(e.value.toLowerCase());
                                    } else {
                                        setNewHierarchy("");
                                    } 
                                }}
                            />
                        </div>
                    </Col>
                    <Col sm="12">
                        <div className="filter-name mt-4">
                            <div className="name-top">Type</div>
                            <Form.Select 
                                size="sm"
                                value={newTag}
                                onChange={e => {
                                    setNewTag(e.target.value);
                                }}
                            >
                                <option value="">-</option>
                                <option value="generic">Generic</option>
                                <option value="specific">Specific</option>
                            </Form.Select>
                        </div>
                    </Col>
                    <Col sm="12">
                        <button 
                            disabled={(newTag.length && newHierarchy.length) ? false : true} 
                            className="btn btn-primary float-end btn-sm mt-4"
                            onClick={e => addNewHierarchy()}
                        >Add</button>
                    </Col>
                </Row>
            </Popover.Body>
        </Popover>
    );  

    const generateRightSide = () => {
        let meppListRightMapping = newMappings.filter(meppListRightSingle => {
            return meppListRightSingle.mapping_status != 'ready_for_mapping'
        });
        return (<div className="bg-white p-3">
            <OverlayTrigger show={showOverlay} placement="bottom" overlay={popoverAddClick} >
                <button 
                    className="btn btn-outline-primary btn-sm float-end"
                    onClick={e => setShowOverlay(!showOverlay)}
                >+ Find & Map</button>
            </OverlayTrigger>
            <div className="right-section-header">Mapped</div>
            <Table borderless size="sm">
                <thead>
                    <tr>
                        <th>Segment</th>
                        <th>Cat</th>
                        <th>Sub Cat</th>
                        <th>Sub Sub Cat</th>
                        <th width="15%">Type</th>
                        <th className="text-end">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {meppListRightMapping.length ? meppListRightMapping.map((meppListRightSingle, meppListRightNo) => {
                        return (generateRightSideSingle(meppListRightSingle, meppListRightNo))
                    }) : <tr>
                        <td colSpan={6}><h6>No Mapping</h6></td>
                    </tr>}   
                </tbody>
            </Table>
        </div>);
    };

    const generateRightSideSuggations = () => {
        let meppListRightMapping = newMappings.filter(meppListRightSingle => {
            return (meppListRightSingle.mapping_status != 'ready_for_mapping' && meppListRightSingle.mapping_status != 'unable_to_map')
        });
        return (<div className="bg-white p-3">
            <div className="right-section-header">Recommendation</div>
            <Table borderless size="sm">
                <thead>
                    <tr>
                        <th>Segment</th>
                        <th>Cat</th>
                        <th>Sub Cat</th>
                        <th>Sub Sub Cat</th>
                        <th width="15%">Type</th>
                        <th className="text-end">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {(meppListRightMapping.filter(meppListRightMapp => meppListRightMapp.id).length == 0) ? suggestionsActual.map((meppListRightSingle, meppListRightNo) => {
                        meppListRightSingle.segment = meppListRightSingle.segment;
                        meppListRightSingle.target_tag = meppListRightSingle.target_tag ? meppListRightSingle.target_tag : "-";
                        return generateRightSideSingleSuggation(meppListRightSingle, meppListRightNo);
                    }) : <tr>
                    <td colSpan={6}><h6>No Recommendation</h6></td>
                </tr>}   
                </tbody>
            </Table>
        </div>);
    };

    const suggestionsActualFun = () => {
        let filterSuggations = [];
        if (suggestions.length && suggestions[0].suggestion) {
            let suggestionArr = suggestions[0].suggestion;
            let newHierarchies = newMappings.map(meppListRightSingle => {
                let newHierarchy = "";
                if (meppListRightSingle.target_cat && meppListRightSingle.target_subcat) {
                    newHierarchy += `${meppListRightSingle.target_cat.toLowerCase()} | ${meppListRightSingle.target_subcat.toLowerCase()}`;
                }
                if (meppListRightSingle.target_sub_subcat) {
                    newHierarchy += ` | ${meppListRightSingle.target_sub_subcat.toLowerCase()}`;
                }
                if (meppListRightSingle.target_sub_sub_subcat) {
                    newHierarchy += ` | ${meppListRightSingle.target_sub_sub_subcat.toLowerCase()}`;
                }
                return newHierarchy;
            });
            suggestionArr.map(suggestion => {
                let isThere = false;
                let targetHierarachy = `${suggestion.target_cat.toLowerCase()} | ${suggestion.target_subcat.toLowerCase()}`;
                if (suggestion.target_sub_subcat) {
                    targetHierarachy += ` | ${suggestion.target_sub_subcat.toLowerCase()}`;
                }
                if (suggestion.target_sub_sub_subcat) {
                    targetHierarachy += ` | ${suggestion.target_sub_sub_subcat.toLowerCase()}`;
                }
                if (newHierarchies.includes(targetHierarachy)) {
                    isThere = true;
                }
                if (!isThere) {
                    filterSuggations.push(suggestion);
                }
            });
        }
        setSuggestionsActual(filterSuggations);
    };

    useEffect(() => {
        suggestionsActualFun();
    },[suggestions, newMappings]);

    const generateButtonActions = (meppListSingle) => {
        let buttons = [];
        let oldRow = mappings.find(mapping => {
            return (mapping.id == meppListSingle.id);
        });
        if (meppListSingle.mapping_status == "added_mapping" || meppListSingle.mapping_status == "approved_mapping") {
            if (!("id" in meppListSingle)) {
                buttons.push(<button 
                    className="btn btn-light btn-sm float-end" 
                    title="Remove Mapping" 
                    onClick={e => deleteTempMapping(meppListSingle)}
                ><FontAwesomeIcon className="text-danger" icon={faWindowClose} /></button>);
            } else if (meppListSingle.mapping_status == "added_mapping") {
                buttons.push(<button 
                    className="btn btn-light btn-sm float-end" 
                    title="Added Mapping"
                    disabled={true}
                >Added Mapping {!isCmtLeadAuditor ? <FontAwesomeIcon icon={faHourglassStart} /> : ""}</button>)
                if (isCmtLeadAuditor) {
                    if (oldRow.mapping_status == meppListSingle.mapping_status) {
                        buttons.push(<button 
                            className="btn btn-light btn-sm float-end" 
                            title="Confirm Added Mapping" 
                            onClick={e => updateMapping(meppListSingle, {
                                mapping_status: "approved_mapping"
                            })}
                        ><FontAwesomeIcon icon={faCheck} /></button>);
                        buttons.push(<button 
                            className="btn btn-light btn-sm float-end" 
                            title="Remove Added Mapping" 
                            onClick={e => updateMapping(meppListSingle, {
                                mapping_status: isCmtLeadAuditor ? "rejected_mapping" : "removed_mapping_under_review"
                            })}
                        ><FontAwesomeIcon icon={faWindowClose} /></button>);
                    }
                } 
            } else {
                buttons.push(<button 
                    className="btn btn-light btn-sm float-end" 
                    title="Removed Mapping"
                    disabled={true}
                >Approved Mapping</button>)
                if (oldRow.mapping_status == meppListSingle.mapping_status) {
                    buttons.push(<button 
                        className="btn btn-light btn-sm float-end" 
                        title="Remove Approved Mapping" 
                        onClick={e => updateMapping(meppListSingle, {
                            mapping_status: isCmtLeadAuditor ? "rejected_mapping" : "removed_mapping_under_review"
                        })}
                    ><FontAwesomeIcon icon={faWindowClose} /></button>);
                }
            }
        }
        if (meppListSingle.mapping_status == "removed_mapping_under_review") {
            buttons.push(<button 
                className="btn btn-light btn-sm float-end" 
                title="Removed Mapping"
                disabled={true}
            >Removed Mapping {!isCmtLeadAuditor ? <FontAwesomeIcon icon={faHourglassStart} /> : ""}</button>)
            if (oldRow.mapping_status == meppListSingle.mapping_status) {
                if (isCmtLeadAuditor) {
                    buttons.push(<button 
                        className="btn btn-light btn-sm float-end" 
                        title="Revert Removed Mapping" 
                        onClick={e => updateMapping(meppListSingle, {
                            mapping_status: "approved_mapping"
                        })}
                    ><FontAwesomeIcon icon={faBackspace} /></button>);
                    buttons.push(<button 
                        className="btn btn-light btn-sm float-end" 
                        title="Confirm Removed Mapping" 
                        onClick={e => updateMapping(meppListSingle, {
                            mapping_status: "rejected_mapping"
                        })}
                    ><FontAwesomeIcon icon={faWindowClose} /></button>);
                }
            }
        }
        if (meppListSingle.mapping_status == "rejected_mapping") {
            buttons.push(<button 
                className="btn btn-light btn-sm float-end" 
                title="Removed Mapping"
                disabled={true}
            >Removed Mapping</button>)
        }
        return (<ButtonGroup size="sm" className="float-end">
            {buttons.map(button => {
                return button;
            })}
        </ButtonGroup>);
    };

    const generateRightSideSingle = (meppListSingle, meppListRightNo) => {
        if (meppListSingle.mapping_status == "unable_to_map") {
            return (<tr>
                <td colSpan={6}>
                    <h6>Unable to Map</h6>
                    <strong>{meppListSingle.reason_for_rejection ? `Reason: ${meppListSingle.reason_for_rejection}` : ""}</strong>
                </td>
            </tr>);
        } else {
            return (<tr>
                <td>{meppListSingle.segment}</td>
                <td>{meppListSingle.target_cat ? meppListSingle.target_cat.toLowerCase() : ""}</td>
                <td>{meppListSingle.target_subcat ? meppListSingle.target_subcat.toLowerCase() : ""}</td>
                <td>{meppListSingle.target_sub_subcat ? (meppListSingle.target_sub_subcat.toLowerCase() + ((meppListSingle.target_sub_sub_subcat && meppListSingle.target_sub_sub_subcat.length) ? (" | " + meppListSingle.target_sub_sub_subcat.toLowerCase()) : "")) : ""}</td>
                <td>
                    <Form.Select size="sm"
                        value={meppListSingle.target_tag}
                        onChange={e => {
                            updateMapping(meppListSingle, {
                                target_tag: e.target.value
                            })
                        }}
                    >
                        {meppListSingle.target_tag ? null : <option value="">-</option>}
                        <option value="generic">Generic</option>
                        <option value="specific">Specific</option>
                    </Form.Select>
                </td>
                <td>{generateButtonActions(meppListSingle)}</td>
            </tr>);
        }
    };

    const updateMapping = (meppListSingle, updateMapObj) => {
        let newNewMappings = [];
        [...newMappings].filter(meppListRightSingle => {
            let valid = true;
            if (meppListRightSingle.mapping_status == 'ready_for_mapping') {
                valid = false;
            }
            return valid;
        }).map(meppListRightSingle => {
            if (meppListRightSingle.target_cat == meppListSingle.target_cat && 
                meppListRightSingle.target_subcat == meppListSingle.target_subcat && 
                meppListRightSingle.target_sub_subcat == meppListSingle.target_sub_subcat && 
                meppListRightSingle.target_sub_sub_subcat == meppListSingle.target_sub_sub_subcat) {
                    Object.keys(updateMapObj).map(updateMapObjKey => {
                        meppListRightSingle[updateMapObjKey] = updateMapObj[updateMapObjKey];
                        meppListRightSingle['updated'] = true;
                    });
            }
            newNewMappings.push(meppListRightSingle);
        });
        setNewMappings(newNewMappings);
    };

    const deleteTempMapping = (meppListSingle) => {
        let newNewMappings = [...newMappings].filter(meppListRightSingle => {
            let valid = true;
            if (meppListRightSingle.mapping_status == 'ready_for_mapping') {
                valid = false;
            }
            if (meppListRightSingle.target_cat == meppListSingle.target_cat && 
                meppListRightSingle.target_subcat == meppListSingle.target_subcat && 
                meppListRightSingle.target_sub_subcat == meppListSingle.target_sub_subcat && 
                meppListRightSingle.target_sub_sub_subcat == meppListSingle.target_sub_sub_subcat) {
                    valid = false;
            }
            return valid;
        });
        setNewMappings(newNewMappings);
    };

    const changeRecomondationTag = (meppListSingle, targetTagValue) => {
        let newSuggestions = [...suggestions];
        newSuggestions[0]['suggestion'].map(newSuggestion => {
            if (meppListSingle.target_cat == newSuggestion.target_cat && 
                meppListSingle.target_subcat == newSuggestion.target_subcat && 
                meppListSingle.target_sub_subcat == newSuggestion.target_sub_subcat && 
                meppListSingle.target_sub_sub_subsubcat == newSuggestion.target_sub_sub_subsubcat) {
                    newSuggestion['target_tag'] = targetTagValue;
                }
        });
        setSuggestions(newSuggestions);
    };

    const generateRightSideSingleSuggation = (meppListSingle, meppListRightNo) => {
        return (<tr key={JSON.stringify(meppListSingle)}>
            <td>{currentSegment}</td>
            <td>{meppListSingle.target_cat ? meppListSingle.target_cat.toLowerCase() : ""}</td>
            <td>{meppListSingle.target_subcat ? meppListSingle.target_subcat.toLowerCase() : ""}</td>
            <td>{meppListSingle.target_sub_subcat ? (meppListSingle.target_sub_subcat.toLowerCase() + ((meppListSingle.target_sub_sub_subcat && meppListSingle.target_sub_sub_subcat.length) ? (" | " + meppListSingle.target_sub_sub_subcat.toLowerCase()) : "")) : ""}</td>
            <td>
                {<Form.Select size="sm"
                    value={meppListSingle.target_tag}
                    onChange={e => {
                        changeRecomondationTag(meppListSingle, e.target.value);
                    }}
                >
                    <option value="">-</option>
                    <option value="generic">Generic</option>
                    <option value="specific">Specific</option>
                </Form.Select>}
            </td>
            <td>
                <ButtonGroup size="sm" className="float-end">
                    <button 
                        disabled={(meppListSingle.target_tag == '-') ? true : false} 
                        className="btn btn-light btn-sm" 
                        title="Confirm"
                        onClick={e => sungestToNewMapping(meppListSingle)}
                    ><FontAwesomeIcon icon={faPlusCircle} /></button>
                </ButtonGroup>
            </td>
        </tr>);
    };

    const sungestToNewMapping = (meppListSingle) => {
        let newMappingsArr = [...newMappings];
        newMappingsArr.push({
            mapping_source: "ml",
            mapping_status: isCmtLeadAuditor ? "approved_mapping" : "added_mapping",
            segment: currentSegment,
            source_tag: hierarchySingleData.source_tag,
            source_retailer: currentRetailer,
            source_cat: hierarchySingleData.source_cat,
            source_subcat: hierarchySingleData.source_subcat,
            source_sub_subcat: hierarchySingleData.source_sub_subcat,
            source_sub_sub_subcat: hierarchySingleData.source_sub_sub_subcat,
            target_tag: meppListSingle.target_tag,
            target_retailer: targetRetailer,
            target_cat: meppListSingle.target_cat,
            target_subcat: meppListSingle.target_subcat,
            target_sub_subcat: meppListSingle.target_sub_subcat,
            target_sub_sub_subcat: meppListSingle.target_sub_sub_subcat,
        });
        setNewMappings(newMappingsArr);
    };

    useEffect(() => {
        setMappings([]);
        setNewMappings([])
        setSuggestions([]);
        setHierarchySingleData(hierarchySingle);
        if (hierarchySingle.source_tag) {
            getMappings();
        }
        getSuggestions();
    },[hierarchySingle]);

    useEffect(() => {
        if (JSON.stringify(hierarchySingle) != JSON.stringify(hierarchySingleData)) {
            let newNewMappings = [];
            newMappings.map(newMapping => {
                newMapping.updated = true;
                newMapping.source_tag = hierarchySingleData.source_tag;
                newNewMappings.push(newMapping);
            });
            setNewMappings(newNewMappings);
        }
    }, [hierarchySingleData]);

    const enableSubmit = () => {
        let unchanged = true;
        if (JSON.stringify(mappings) != JSON.stringify(newMappings)) {
            unchanged = false;
        }
        /*if (JSON.stringify(hierarchySingle) != JSON.stringify(hierarchySingleData)) {
            unchanged = false;
        }*/
        if (!hierarchySingleData.source_tag) {
            unchanged = true;
        }
        return unchanged;
    };

    const unableToMapFun = () => {
        hierarchySingleData.source_tag = 'generic';
        setShowUnableToMapReason(true);
    };

    const submitMapping = (submitType) => {
        let mappingArgs = [];
        if (newMappings.length) {
            newMappings.map(newMapping => {
                newMapping.action = submitType;
                newMapping.source_retailer = currentRetailer;
                mappingArgs.push(newMapping);
            });
        } else {
            hierarchySingleData.action = submitType;
            if (submitType == 'submit') {
                hierarchySingleData.mapping_status = 'ready_for_mapping';
            }
            if (submitType == 'unable_to_map') {
                hierarchySingleData.mapping_status = 'unable_to_map';
                hierarchySingleData.source_tag = 'generic';
                hierarchySingleData.reason_for_rejection = (reasonCode.toLowerCase() == "other") ? notes : reasonCode;
                setShowUnableToMapReason(false);
            }
            hierarchySingleData.source_retailer = currentRetailer;
            hierarchySingleData.target_retailer = targetRetailer;
            hierarchySingleData.segment = currentSegment;
            mappingArgs.push(hierarchySingleData)
        }
        setLoading(true);
        let api = '/categoryMapping/mappings';
        hardApiCall("PUT", api, {}, {
            "mappings" : mappingArgs
        })
        .then((response) => {
            setLoading(false);
            setNotes("");
            if (typeof response.data == 'object') {
                if (response.data.mappings) {
                    setMappings(JSON.parse(JSON.stringify(response.data.mappings)));
                    let newMappings = [...response.data.mappings].filter(meppListRightSingle => {
                        let valid = true;
                        if (meppListRightSingle.mapping_status == 'ready_for_mapping') {
                            valid = false;
                        }
                        return valid;
                    });
                    setNewMappings(JSON.parse(JSON.stringify(newMappings)));
                } else {
                    setMappings([]);
                    setNewMappings([]);
                }
                if (response.data.suggestions) {
                    setSuggestions(response.data.suggestions);
                } else {
                    setSuggestions([]);
                }
            } else {
                toast.error("There is an error in data response!");
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log("ERROR", error);
            toast.error("There is an error in data response!");
        });
    };

    if (newMappings.length) {
        console.log('newMappings', newMappings);
    }

    return (<>
        <Loader loading={loading} doNotOverFlow={true} />
        <Col sm="6" className="pe-1 row-sections">{generateLeftSide()}</Col>
        <Col sm="6" className="ps-1 row-sections">
            {generateRightSide()}
            {generateRightSideSuggations()}
        </Col>
        <Col sm="12">
            <div className="bg-white p-2 mt-2 d-flex flex-row-reverse">
                <ButtonGroup size="sm">
                    {newMappings.length ? "" : <Button 
                        variant="outline-primary" 
                        className="me-1"
                        onClick={e => unableToMapFun()}
                    >Unable to Map</Button>}
                    <Button 
                        variant="primary" 
                        disabled={enableSubmit()}
                        onClick={e => submitMapping('submit')}
                    >Submit</Button>
                </ButtonGroup>
                {/*<InputGroup size="sm" className="pull-right">
                    <Form.Control 
                        placeholder="Notes" 
                        className="border-0" 
                        value={notes} 
                        onChange={e => setNotes(e.target.value)}
                    />
                    {newMappings.length ? "" : <Button 
                        variant="outline-primary" 
                        className="me-1"
                        onClick={e => submitMapping('unable_to_map')}
                    >Unable to Map</Button>}
                    <Button 
                        variant="primary" 
                        disabled={enableSubmit()}
                        onClick={e => submitMapping('submit')}
                    >Submit</Button>
                </InputGroup>*/}
            </div>
        </Col>
        <Col sm="12"><hr className="separator-hr"/></Col>
        <Modal 
            show={showUnableToMapReason}
            onHide={e => setShowUnableToMapReason(false)}
            centered
            className="unableToMapModal"
        >
            <Modal.Header closeButton>
                <div className="modal-title">
                    <div className="title">
                        <h3 className="mb-0">Unable To Map Reason Codes</h3>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body className="overflow-auto">
                <Table>
                    <tr>
                        <th>Segment</th><td>{currentSegment}</td>
                    </tr>
                    <tr>
                        <th>Source Cat</th><td>{hierarchySingleData.source_cat.toLowerCase()}</td>
                    </tr>
                    <tr>
                        <th>Source Sub Cat</th><td>{hierarchySingleData.source_subcat.toLowerCase()}</td>
                    </tr>
                    <tr>
                        <th>Source Sub Sub Cat</th><td>{(hierarchySingleData.source_sub_subcat ? hierarchySingleData.source_sub_subcat.toLowerCase() : "") + (hierarchySingleData.source_sub_sub_subcat ? (" | " + hierarchySingleData.source_sub_sub_subcat.toLowerCase()) : "")}</td>
                    </tr>
                    <tr>
                        <th>Type</th><td>{hierarchySingleData.source_tag}</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <Form.Select 
                                value={reasonCode}
                                onChange={e => setReasonCode(e.target.value)}
                                size="sm"
                                className="mb-2 mt-2"
                            >
                                <option value="">Select Reason</option>
                                <option value="Non-Pet Category">Non-Pet Category</option>
                                <option value="No Bungee Taxonomy Available To Map">No Bungee Taxonomy Available To Map</option>
                                <option value="Irrelevant ML Recommendation">Irrelevant ML Recommendation</option>
                                <option value="other">Other</option>
                            </Form.Select>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            {(reasonCode.toLowerCase() == "other") ? <Form.Control 
                                as="textarea" 
                                className="mb-2"
                                rows={3} 
                                placeholder="Add your Reason" 
                                value={notes}
                                onChange={e => setNotes(e.target.value)}
                            /> : ""}
                            <button 
                                disabled={((reasonCode.length == 0) || (reasonCode.toLowerCase() == "other" && notes.length == 0))} 
                                className="btn btn-primary btn-sm float-end"
                                onClick={e => submitMapping('unable_to_map')}
                            >Confirm</button>
                        </td>
                    </tr>
                </Table>
            </Modal.Body>
        </Modal>
    </>);
};

export default MapRow;