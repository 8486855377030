import React, { useEffect, useState, useContext } from "react";
//import liftRightData from "./left-right.json";
import { Button, ButtonGroup, Col, Form, InputGroup, Nav, NavDropdown, Navbar, Pagination, Row, Table } from "react-bootstrap";
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faLongArrowAltLeft, faLongArrowAltRight, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import MapRow from "./MapRow";
import { hardApiCall, getSegmentLib, generatePaginationString } from "../common/common";
import PaginationCustom from "../common/PaginationCustom/PaginationCustom";
import { toast } from 'react-toastify';
import { generatePagination } from "../../utils/routeBasedOperations";
import SideMenuCommonFilter from "../../contextApi/SideMenuCommonFilter";
import SideMenuCommonFilterLib from "../../contextApi/SideMenuCommonFilterLib";
import Loader from "../common/Loader/Loader";
import moment from "moment";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

const Dashboard = () => {

    const [loading, setLoading] = useState(false);
    const [sideMenuCommonFilterContext, setSideMenuCommonFilterContext] = useContext(SideMenuCommonFilter);
    const [sideMenuCommonFilterLibContext, setSideMenuCommonFilterLibContext] = useContext(SideMenuCommonFilterLib);
    const [bungeeList, setBungeeList] = useState([]);
    const [retailerList, setRetailerlist] = useState(sideMenuCommonFilterLibContext.retailers);
    const [targetRetailerList, setTargetRetailerList] = useState(sideMenuCommonFilterLibContext.targetRetailers);
    const [targetRetailer, setTargetRetailer] = useState("bungee");
    const [hierarchyList, setHierarchyList] = useState([]);
    const [bungeeHierarchyList, setBungeeHierarchyList] = useState([]);
    const [currentSegment, setCurrentSegment] = useState(sideMenuCommonFilterContext.segment.values().next().value);
    const [currentRetailer, setCurrentRetailer] = useState(sideMenuCommonFilterContext.source_retailer.values().next().value);   
    const [dateRange, setDateRange] = useState([null, null]);
    let letSideLabel = "";
    if (sideMenuCommonFilterContext.source_cat.values().next().value) {
        letSideLabel = sideMenuCommonFilterContext.source_cat.values().next().value;
        letSideLabel += " | " + sideMenuCommonFilterContext.source_subcat.values().next().value;
        if (sideMenuCommonFilterContext.source_sub_subcat.values().next().value) {
            letSideLabel += " | " + sideMenuCommonFilterContext.source_sub_subcat.values().next().value;
        }
        if (sideMenuCommonFilterContext.source_sub_sub_subcat.values().next().value) {
            letSideLabel += " | " + sideMenuCommonFilterContext.source_sub_sub_subcat.values().next().value;
        }
    }
    const [currentLeftSide, setCurrentLeftSide] = useState(letSideLabel);
    const [currentRightSide, setCurrentRightSide] = useState("");
    const [segmentLib, setSegmentLib] = useState(getSegmentLib());
    const limit = 5;
    const [page, setPage] = useState(1);
    const [pageinationArr, setPageinationArr] = useState([]);
    const [filterStatus, setFilterStatus] = useState("All");
    const [currentLeftSideGroup, setCurrentLeftSideGroup] = useState([]);
    const isCmtLeadAuditor = (localStorage.getItem("isCmtLeadAuditor") == "true");
    const [filterStatusList, setFilterStatusList] = useState({
        "All": {},
        "Not Mapped": {
            "mapping_status": "ready_for_mapping"
        },
        "Added Mapping": {
            "mapping_status": "added_mapping"
        },
        "Unable to Map": {
            "mapping_status": "unable_to_map"
        },
        "Removed Mapping": {
            "mapping_status": "removed_mapping_under_review"
        },
        "Recommendation": {
            "mapping_status": "recommendation"
        },
        "Approved Mapping": {
            "mapping_status": "approved_mapping"
        },
        "Specific to Generic": {
            "mapping_status": "approved_mapping",
            "source_tag": "specific",
            "target_tag": "generic",
        },
        "Specific to Specific": {
            "mapping_status": "approved_mapping",
            "source_tag": "specific",
            "target_tag": "specific",
        },
        "Generic to Generic": {
            "mapping_status": "approved_mapping",
            "source_tag": "generic",
            "target_tag": "generic",
        },
        "Generic to Specific": {
            "mapping_status": "approved_mapping",
            "source_tag": "generic",
            "target_tag": "specific",
        }
    });

    const getHierarchy = () => {
        setLoading(true);
        let api = '/categoryMapping/hierarchy';
        hardApiCall("GET", api, {}, {
            source_retailer: currentRetailer,
            target_retailer: targetRetailer,
            segment: currentSegment.toLowerCase()
        })
        .then((response) => {
            setLoading(false);
            if (typeof response.data == 'object') {
                setHierarchyList(response.data);
            } else {
                toast.error("There is an error in data response!");
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log("ERROR", error);
            toast.error("There is an error in data response!");
        });
    };

    const getBungeeHierarchy = () => {
        setLoading(true);
        let api = '/categoryMapping/hierarchy';
        hardApiCall("GET", api, {}, {
            source_retailer: targetRetailer,
            segment: currentSegment.toLowerCase()
        })
        .then((response) => {
            setLoading(false);
            if (typeof response.data == 'object') {
                setBungeeHierarchyList(response.data);
            } else {
                toast.error("There is an error in data response!");
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log("ERROR", error);
            toast.error("There is an error in data response!");
        });
    };

    useEffect(() => {
        if (sideMenuCommonFilterLibContext.retailers.length) {
            setRetailerlist(sideMenuCommonFilterLibContext.retailers);
            if (currentRetailer.length == 0) {
                setCurrentRetailer(sideMenuCommonFilterLibContext.retailers[0]);
            }
        }
    },[sideMenuCommonFilterLibContext]);

    useEffect(() => {
        setCurrentLeftSideGroup([]);
        setFilterStatus("All");
        if (currentRetailer.length && currentSegment.length) {
            getHierarchy();
            getBungeeHierarchy();
        } else {
            setHierarchyList([]);
            setBungeeHierarchyList([]);
            setCurrentLeftSide("");
        }
    },[currentRetailer, currentSegment, targetRetailer]);

    useEffect(() => {
        let pageinationArrNew = generatePagination(filteredList().length, limit, page);
        setPageinationArr(pageinationArrNew);
    }, [hierarchyList, page, currentLeftSide, currentLeftSideGroup]);

    useEffect(() => {
        let currentLeftSideArr = currentLeftSide.split(' | ');
        let newSideMenuCommonFilterContext = {...sideMenuCommonFilterContext};
        if (currentLeftSideArr[0]) {
            newSideMenuCommonFilterContext['source_cat'] = new Set([currentLeftSideArr[0]]);
        } else {
            newSideMenuCommonFilterContext['source_cat'] = new Set([""]);
        }
        if (currentLeftSideArr[1]) {
            newSideMenuCommonFilterContext['source_subcat'] = new Set([currentLeftSideArr[1]]);
        } else {
            newSideMenuCommonFilterContext['source_subcat'] = new Set([""]);
        }
        if (currentLeftSideArr[2]) {
            newSideMenuCommonFilterContext['source_sub_subcat'] = new Set([currentLeftSideArr[2]]);
        } else {
            newSideMenuCommonFilterContext['source_sub_subcat'] = new Set([""]);
        }
        if (currentLeftSideArr[3]) {
            newSideMenuCommonFilterContext['source_sub_sub_subcat'] = new Set([currentLeftSideArr[3]]);
        } else {
            newSideMenuCommonFilterContext['source_sub_sub_subcat'] = new Set([""]);
        }
        setSideMenuCommonFilterContext(newSideMenuCommonFilterContext);
    }, [currentLeftSide])

    const renderPagination = () => {
        return (<Pagination className="mt-3 float-end">
            <InputGroup>
                <Button disabled variant="light" className="me-2">{generatePaginationString(filteredList().length, limit, page)} </Button>
                <PaginationCustom
                    pageinationArr={pageinationArr}
                    pageClicked={pageClicked}
                    page={page}
                />
            </InputGroup>
        </Pagination>);
    };

    const pageClicked = (pageNo) => {
        setPage(pageNo);
    };

    const filteredList = () => {
        return hierarchyList.filter(hierarchyListSingle => {
            let valid = true;
            if (currentLeftSide.length) {
                let mainHierarchy = `${hierarchyListSingle.source_cat.toLowerCase()} | ${hierarchyListSingle.source_subcat.toLowerCase()}`;
                if (hierarchyListSingle.source_sub_subcat) {
                    mainHierarchy += ` | ${hierarchyListSingle.source_sub_subcat.toLowerCase()}`;
                }
                if (hierarchyListSingle.source_sub_sub_subcat) {
                    mainHierarchy += ` | ${hierarchyListSingle.source_sub_sub_subcat.toLowerCase()}`;
                }
                if (currentLeftSide.toLowerCase() != mainHierarchy.toLowerCase()) {
                    valid = false;
                }
            }
            if (filterStatus.toLocaleLowerCase() != "all" && valid) {
                valid = false;
                currentLeftSideGroup.map(currentLeftSideGroupSingle => {
                    if (hierarchyListSingle.source_cat == currentLeftSideGroupSingle.source_cat && 
                        hierarchyListSingle.source_subcat == currentLeftSideGroupSingle.source_subcat && 
                        hierarchyListSingle.source_sub_subcat == currentLeftSideGroupSingle.source_sub_subcat &&
                        hierarchyListSingle.source_sub_sub_subcat == currentLeftSideGroupSingle.source_sub_sub_subcat) {
                            valid = true;
                        }
                });
            }
            return valid;
        });
    };

    const currentPage = () => {
        let newArr = [];
        let filteredListArr = filteredList();
        if (filteredListArr.length) {
            let start = (page-1)*limit;
            let end = start+limit-1;
            if (end >= filteredListArr.length) {
                end = filteredListArr.length - 1;
            }
            for (let i = start; i <= end; i++) {
                newArr.push(filteredListArr[i]);
            }
        }
        return newArr;
    };

    const getHierarchyFilters = () => {
        setLoading(true);
        let api = '/categoryMapping/hierarchy';
        let filters = {
            source_retailer: currentRetailer,
            target_retailer: targetRetailer,
            segment: currentSegment.toLowerCase()
        };
        Object.keys(filterStatusList[filterStatus]).map(filterStatusKey => {
            filters[filterStatusKey] = filterStatusList[filterStatus][filterStatusKey]
        });
        if (dateRange[0] && dateRange[1]) {
            filters['startDate'] = dateRange[0].format('YYYY-MM-DD HH:mm:ss');
            filters['endDate'] = dateRange[1].format('YYYY-MM-DD HH:mm:ss');
        }
        hardApiCall("GET", api, {}, filters)
        .then((response) => {
            setLoading(false);
            setCurrentLeftSideGroup(response.data);
        })
        .catch((error) => {
            setLoading(false);
            console.log("ERROR", error);
            toast.error("There is an error in data response!");
        });
    }

    
    useEffect(() => {
        if (filterStatus.toLocaleLowerCase() != "all" || (dateRange[0] && dateRange[1])) {
            getHierarchyFilters();
        } else {
            setCurrentLeftSideGroup([]);
        }
    }, [filterStatus, dateRange]);

    const dateRangeSelect = (start, end, label) => {
        if (start.isValid() && end.isValid()) {
            setDateRange([start, end]);
        } else {
            if (dateRange[0] != null && dateRange[1] != null) {
                setDateRange([null, null]);
            }
        }
    };

    return (<>
        <Loader loading={loading} doNotOverFlow={true} />
        <div className="page-container-default">
            <div className="custom-container dark-shade p-2">
                <Row className="mb-2">
                    {/*<Col sm="5"></Col>
                    <Col sm="2">
                        <div className="w-100">
                            <div className="text-center">
                                <div className="middle-button-container">
                                    <Button variant="outline-secondary" size="sm" className="middle-button">
                                        <FontAwesomeIcon className="align-top" icon={faLongArrowAltLeft}/>
                                        <FontAwesomeIcon className="align-bottom" icon={faLongArrowAltRight}/>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm="5">
                        <ButtonGroup size="sm" className="float-end">
                            <button className="btn btn-light">Download Mapping Template</button>
                            <button className="btn btn-light">Upload Mapping</button>
                        </ButtonGroup>
                        <Form.Check className="float-end me-4 mt-1" checked={true} type="checkbox" label="Recommendation" />
                    </Col>*/}
                    <Col sm="6" className="pe-1">
                        <div className="bg-white p-3 pt-4 ">
                            <div className="filter-header">Source</div>
                            <Row className="mt-4">
                                <Col>
                                    <div className="filter-name">
                                        <div className="name-top">Select Retailer</div>
                                        <Select 
                                            onChange={e => {
                                                if (e) {
                                                    setCurrentRetailer(e.value.toLowerCase());
                                                    let newSideMenuCommonFilterContext = {...sideMenuCommonFilterContext};
                                                    newSideMenuCommonFilterContext['source_retailer'] = new Set([e.value.toLowerCase()]);
                                                    setSideMenuCommonFilterContext(newSideMenuCommonFilterContext);
                                                    setCurrentLeftSide("");
                                                }
                                            }}
                                            value={currentRetailer.length ? {
                                                label: currentRetailer.toUpperCase(),
                                                value: currentRetailer
                                            } : null}
                                            placeholder="Search Retailer"
                                            options={retailerList.map(retailerListSingle => {
                                                return {
                                                    label: retailerListSingle.toUpperCase(),
                                                    value: retailerListSingle.toLowerCase()
                                                }
                                            })}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="filter-name">
                                        <div className="name-top">Select Segment</div>
                                        <Select 
                                            onChange={e => {
                                                if (e) {
                                                    setCurrentSegment(e.value.toLowerCase());
                                                    let newSideMenuCommonFilterContext = {...sideMenuCommonFilterContext};
                                                    newSideMenuCommonFilterContext['segment'] = new Set([e.value.toLowerCase()]);
                                                    setSideMenuCommonFilterContext(newSideMenuCommonFilterContext);
                                                } else {
                                                    setCurrentSegment("");
                                                    let newSideMenuCommonFilterContext = {...sideMenuCommonFilterContext};
                                                    newSideMenuCommonFilterContext['segment'] = new Set([""]);
                                                    setSideMenuCommonFilterContext(newSideMenuCommonFilterContext);
                                                }
                                                setCurrentLeftSide("");
                                            }}
                                            value={currentSegment.length ? {
                                                label: segmentLib.find(segmentLibSingle => {
                                                    return segmentLibSingle.toLocaleLowerCase() == currentSegment;
                                                }),
                                                value: currentSegment
                                            } : null}
                                            isClearable={true}
                                            placeholder="Search Source Segment"
                                            options={segmentLib.map(segmentSingle => {
                                                return {
                                                    label: segmentSingle,
                                                    value: segmentSingle.toLowerCase()
                                                }
                                            })}
                                        />
                                    </div>
                                </Col>
                                {(currentRetailer.length && currentSegment.length) ? <Col>
                                    <div className="filter-name">
                                        <div className="name-top">Select Hierarchy</div>
                                        <Select 
                                            isClearable={true}
                                            placeholder="Search Source Hierarchy"
                                            options={hierarchyList.map(hierarchySingle => {
                                                let curentLabel = hierarchySingle.source_cat.toUpperCase();
                                                curentLabel += " | " + hierarchySingle.source_subcat.toUpperCase();
                                                if (hierarchySingle.source_sub_subcat) {
                                                    curentLabel += " | " + hierarchySingle.source_sub_subcat.toUpperCase();
                                                }
                                                if (hierarchySingle.source_sub_sub_subcat) {
                                                    curentLabel += " | " + hierarchySingle.source_sub_sub_subcat.toUpperCase();
                                                }
                                                return {
                                                    label: curentLabel,
                                                    value: curentLabel
                                                }
                                            })}
                                            value={currentLeftSide.length ? {
                                                label: currentLeftSide.toUpperCase(),
                                                value: currentLeftSide.toUpperCase()
                                            } : null}
                                            onChange={e => {
                                                if (e) {
                                                    setCurrentLeftSide(e.value.toLowerCase());
                                                } else {
                                                    setCurrentLeftSide("");
                                                } 
                                            }}
                                        />
                                    </div>
                                </Col>: ""}
                            </Row>
                        </div>
                    </Col>
                    <Col sm="6" className="ps-1">
                        <div className="bg-white p-3 pt-4">
                            <div className="filter-header">Target</div>
                            <Row className="mt-4">
                                <Col>
                                    <div className="filter-name">
                                        <div className="name-top">Select Retailer</div>
                                        <Form.Select 
                                            size="sm"
                                            value={targetRetailer}
                                            onChange={e => {
                                                setTargetRetailer(e.target.value);
                                            }}                                        
                                        >
                                            {targetRetailerList.map(targetRetailerSingle => {
                                                return <option value={targetRetailerSingle}>{targetRetailerSingle.toUpperCase()}</option>
                                            }
                                            )}
                                        </Form.Select>
                                    </div>
                                </Col>
                                {(currentRetailer.length && currentSegment.length && isCmtLeadAuditor) ? <Col>
                                    <div className="filter-name">
                                        <div className="name-top">Select Hierarchy Status</div>
                                        <Select 
                                            placeholder="Search Hierarchy Status"
                                            options={Object.keys(filterStatusList).map(filterStatusKey => {
                                                return {
                                                    label: filterStatusKey,
                                                    value: filterStatusKey
                                                }
                                            })}
                                            value={{
                                                "label": filterStatus,
                                                "value": filterStatus
                                            }}
                                            onChange={e => {
                                                setFilterStatus(e.value);
                                            }}
                                        />
                                    </div>
                                </Col> : ""}
                            </Row>
                            {/*<Row className="">
                                <Col sm="12">
                                    <div className="filter-name mt-4">
                                        <div className="name-top">Select Hierarchy</div>
                                        <Select 
                                            isClearable={true}
                                            placeholder="Search Target Hierarchy"
                                            options={bungeeHierarchyList.map(hierarchySingle => {
                                                let curentLabel = hierarchySingle.source_cat.toUpperCase();
                                                curentLabel += " | " + hierarchySingle.source_subcat.toUpperCase();
                                                curentLabel += " | " + hierarchySingle.source_sub_subcat.toUpperCase();
                                                if (hierarchySingle.source_sub_sub_subcat) {
                                                    curentLabel += " | " + hierarchySingle.source_sub_sub_subcat.toUpperCase();
                                                }
                                                return {
                                                    label: curentLabel,
                                                    value: curentLabel
                                                }
                                            })}
                                            onChange={e => {
                                                if (e) {
                                                    setCurrentRightSide(e.value);
                                                } else {
                                                    setCurrentRightSide("");
                                                } 
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>*/}
                        </div>
                    </Col>
                    <Col sm="12"><hr className="separator-hr"/></Col>
                    {currentPage().length ? currentPage().map(hierarchySingle => {
                        return <MapRow 
                            key={JSON.stringify(hierarchySingle)}
                            hierarchySingle={hierarchySingle} 
                            currentSegment={currentSegment}
                            targetRetailer={targetRetailer}
                            currentRetailer={currentRetailer}
                            bungeeHierarchyList={bungeeHierarchyList}
                        />
                    }): <h6>No Data</h6>}
                    <Col sm="12">{currentSegment.length ? renderPagination() : "Please select segment!"}</Col>
                </Row>
            </div>
        </div>
    </>);
};

export default Dashboard;