import React, { useEffect, useState } from "react";
import * as pageRoutes from "../../pageRoutes";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import { addParamsToLink } from "../common/common";
import ReportOtherExceptionSSToMG from "./ReportOtherExceptionSSToMG";
import ReportOtherExceptionSSToMS from "./ReportOtherExceptionSSToMS";
import ReportOtherExceptionSGToMG from "./ReportOtherExceptionSGToMG";

const ReportOtherException = () => {
    const location = useLocation();

    const generateNavs = () => {
        return (<>
            <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.reportSSToMGPage)}>Single Specific to Multiple Generic</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.reportSSToMSPage)}>Single Specific to Multiple Specific</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.reportSGToMGPage)}>Single Generic to Multiple Generic</NavDropdown.Item>
            {/*<NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.reportRecommendationPage)}>Recommendation</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to={addParamsToLink(pageRoutes.reportAddedMappingPage)}>Added Mapping</NavDropdown.Item>*/}
        </>);
    };

    const renderReport = () => {
        if (location.pathname == pageRoutes.reportSSToMGPage) {
            return <ReportOtherExceptionSSToMG id="1" />;
        } else if (location.pathname == pageRoutes.reportSSToMSPage) {
            return <ReportOtherExceptionSSToMS id="1" />;
        } else if (location.pathname == pageRoutes.reportSGToMGPage) {
            return <ReportOtherExceptionSGToMG id="1" />;
        }
    };
    
    return (<>
        <Navbar className="bungee-gaps-nav p-0">
            <Nav className="me-auto">
                {generateNavs()}
            </Nav>
        </Navbar>
        <hr className='section-divider mb-4' />
        {renderReport()}
    </>);
};

export default ReportOtherException;