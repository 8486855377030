import React, { useEffect, useState, useContext } from "react";
import { Table} from "react-bootstrap";
import { getSegmentLib, hardApiCall, generatePaginationString } from "../common/common";
import { toast } from "react-toastify";
import SideMenuCommonFilter from "../../contextApi/SideMenuCommonFilter";
import * as pageRoutes from "../../pageRoutes";
import { useNavigate } from "react-router-dom";

const ReportOtherExceptionRightSection = (props) => {
    const {
        left
    } = props;

    const [sideMenuCommonFilterContext, setSideMenuCommonFilterContext] = useContext(SideMenuCommonFilter);
    const [loading, setLoading] = useState(false);
    const [mappings, setMappings] = useState([]);
    const [right, setRight] = useState([]);

    const navigate = useNavigate();

    const mapRecord = (rec) => {
        let newSideMenuCommonFilterContext = {...sideMenuCommonFilterContext};
        console.log('newSideMenuCommonFilterContext', newSideMenuCommonFilterContext);
        Object.keys(rec).map(singleRecIndex => {
            newSideMenuCommonFilterContext[singleRecIndex] = new Set([rec[singleRecIndex]]);
        });
        setSideMenuCommonFilterContext(newSideMenuCommonFilterContext);
        let redirectPath = pageRoutes.dashboardPage;
        navigate(redirectPath);
    }

    const getMappings = () => {
        console.log('getMappings');
        setLoading(true);
        let api = '/categoryMapping/mapping';
        hardApiCall("GET", api, {}, {
            "segment" : left.segment.toLowerCase(),
            "source_retailer" : left.source_retailer,
            "target_retailer" : left.target_retailer,
            "source_tag": left.source_tag,
            "source_cat" : left.source_cat,
            "source_subcat" : left.source_subcat,
            "source_sub_subcat" : left.source_sub_subcat,
            "source_sub_sub_subcat" : left.source_sub_sub_subcat,
        })
        .then((response) => {
            setLoading(false);
            if (typeof response.data == 'object') {
                setMappings(response.data);
            } else {
                toast.error("There is an error in data response!");
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log("ERROR", error);
            toast.error("There is an error in data response!");
        });
    };

    useEffect(() => {
        setMappings([]);
        getMappings();
    },[left]);

    useEffect(() => {
        let newRight = mappings.filter(mapping => {
            return (mapping.mapping_status == "approved_mapping");
        });
        setRight(newRight);
    },[mappings]);


    return (<Table borderless size="sm">
        <thead>
            <tr>
                <th>Retailer</th>
                <th>Segment</th>
                <th>Cat</th>
                <th>Sub Cat</th>
                <th>Sub Sub Cat</th>
                <th>Type</th>
                <th className="float-end">Action</th>
            </tr>
        </thead>
        <tbody>
            {loading ? <tr><td colSpan={7}>Loading</td></tr> : ""}
            {right.map(rightSingle => {
                return <tr>
                <td>{rightSingle.target_retailer}</td>
                <td>{rightSingle.segment}</td>
                <td>{rightSingle.target_cat.toLowerCase()}</td>
                <td>{rightSingle.target_subcat.toLowerCase()}</td>
                <td>{(rightSingle.target_sub_subcat ? rightSingle.target_sub_subcat.toLowerCase() : "") + (rightSingle.target_sub_sub_subcat ? (" | " + rightSingle.target_sub_sub_subcat.toLowerCase()) : "")}</td>
                <td>{rightSingle.target_tag}</td>
                <td className="float-end">
                    <button onClick={e => {
                        mapRecord({
                            source_retailer: rightSingle.source_retailer,
                            segment: rightSingle.segment,
                            source_cat: rightSingle.source_cat,
                            source_subcat: rightSingle.source_subcat,
                            source_sub_subcat: rightSingle.source_sub_subcat,
                            source_sub_sub_subcat: rightSingle.source_sub_sub_subcat,
                            source_tag: rightSingle.source_tag
                        })
                    }} className="btn btn-sm btn-outline-primary">Action</button>
                </td>
            </tr>
            })}
        </tbody>
    </Table>);
};

export default ReportOtherExceptionRightSection;