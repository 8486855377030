import React, {useEffect, useState, lazy} from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addParamsToLink } from "../common";
import {
  faPowerOff,
  faQuestion
} from "@fortawesome/free-solid-svg-icons";
import * as pageRoutes from "../../../pageRoutes";
import { removeValueFromLocalStorage } from "../../../utils/localStorageOperations";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import "./sideBarMenu.scss";

const SideBarMenu = () => {
  let location = useLocation();
  let navigationConfig = {
    "Reports": ["report", "reportNotMapped", "reportPendingAction", "reportUnableMapped", "reportSSToMG", "reportSSToMS", "reportSGToMG", "reportRecommendation", "reportAddedMapping"],
  };
  const [parentNav, setParentNav] = useState("");
  let modulesAccess = [];
  if (localStorage.getItem('modules')) {
    modulesAccess = localStorage.getItem('modules').split(',');
  }

  useEffect(() => {
    let navigationParent = "";
    Object.keys(navigationConfig).map(navigationKey => {
      let pathNameArr = location.pathname.split('/').filter(pathnameSingle => {
        return pathnameSingle.length;
      });
      if(pathNameArr.length && navigationConfig[navigationKey].includes(pathNameArr[0])) {
        navigationParent = navigationKey;
      }
    });
    setParentNav(navigationParent);
  }, [location]);

  const logoutAccount = () => {
    removeValueFromLocalStorage([
      "accessToken",
      "idToken",
      "expiryTime",
      "userData",
      "email",
      "isAdmin",
      "isCmtLeadAuditor"
    ]);
  };

  const popover = (
    <Popover id="popover-basic" style={{'max-width':'500px'}}>
      <Popover.Header as="h3">Coming soon!</Popover.Header>
      <Popover.Body>
        1. Parity changes (Suppression, download , Product landscape)<br/> &nbsp;&nbsp;&nbsp;&nbsp;with existing version of Retailscape.<br/>
        2. New filters like "Include Private label", "Merch classification 3" etc.<br/>
        3. New user experience changes.
      </Popover.Body>
    </Popover>
  );

  return (<Navbar style = {{ backgroundColor : "#0069BF"}} variant="dark" className="bungee-nav bg-blue">
    <Container fluid>
      <Link 
        to={addParamsToLink(pageRoutes.dashboardPage)}
        className="brand-logo-container">
        <Navbar.Brand>
          <img 
            height="21"
            className="mx-auto"
            src={process.env.PUBLIC_URL + "/images/bungee-logo-mini.png"} 
          />
        </Navbar.Brand>
      </Link>
      <div className="brand-slogan-container">
        <Link to={addParamsToLink(pageRoutes.dashboardPage)}>CMT</Link>
      </div>
      <span className="text-white pe-2">&#124;</span>
      <Nav className="me-auto">
        <Nav.Link as={NavLink} to={addParamsToLink(pageRoutes.dashboardPage)}>Dashboard</Nav.Link>
        {/*<Nav.Link as={NavLink} to={addParamsToLink(pageRoutes.reviewPage)}>Review</Nav.Link>
        <Nav.Link as={NavLink} to={addParamsToLink(pageRoutes.hierarchyPage)}>Hierarchy</Nav.Link>*/}
        <Nav.Link className={(parentNav == "Reports") ? "active" : ""} as={NavLink} to={addParamsToLink(pageRoutes.reportPage)}>Reports</Nav.Link>
      </Nav>
      <Nav>
        <a className="nav-link" target="_blank" title="Download SOP" href={process.env.PUBLIC_URL + "/docs/Category-Normalization-Process-SOP-V1.docx"}>
          <FontAwesomeIcon icon={faQuestion}/>
        </a>
        <NavLink style = {{ color : "white"}} className="nav-link" to={pageRoutes.loginPage} onClick={logoutAccount}><FontAwesomeIcon icon={faPowerOff} /></NavLink>
      </Nav>
    </Container>
</Navbar>);
};

export default SideBarMenu;
