import React, { useEffect, useState, useContext } from "react";
import { Button, Col, Form, InputGroup, Pagination, Row, Table } from "react-bootstrap";
import { getSegmentLib, hardApiCall, generatePaginationString } from "../common/common";
import { toast } from "react-toastify";
import Select from 'react-select';
import SideMenuCommonFilterLib from "../../contextApi/SideMenuCommonFilterLib";
import Loader from "../common/Loader/Loader";
import PaginationCustom from "../common/PaginationCustom/PaginationCustom";
import { generatePagination } from "../../utils/routeBasedOperations";
import SideMenuCommonFilter from "../../contextApi/SideMenuCommonFilter";
import * as pageRoutes from "../../pageRoutes";
import { useNavigate } from "react-router-dom";
import RowLabel from "../common/RowLabel/RowLabel";
import moment from "moment";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

const ReportUnableMapped = () => {

    const [loading, setLoading] = useState(false);
    const [sideMenuCommonFilterLibContext, setSideMenuCommonFilterLibContext] = useContext(SideMenuCommonFilterLib);
    const [sideMenuCommonFilterContext, setSideMenuCommonFilterContext] = useContext(SideMenuCommonFilter);
    const [retailerList, setRetailerlist] = useState([]);
    const [currentSegment, setCurrentSegment] = useState(sideMenuCommonFilterContext.segment.values().next().value);
    const [currentRetailer, setCurrentRetailer] = useState(sideMenuCommonFilterContext.source_retailer.values().next().value);
    const  date_range = [...sideMenuCommonFilterContext.date_range];
    const [dateRange, setDateRange] = useState((date_range.length == 2) ? date_range : [date_range[0], date_range[0]]);
    const [segmentLib, setSegmentLib] = useState(getSegmentLib());
    const [data, setData] = useState([]);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageinationArr, setPageinationArr] = useState([]);
    const [sort, setSort] = useState("asc|source_retailer");

    const navigate = useNavigate();

    const mapRecord = (rec) => {
        let newSideMenuCommonFilterContext = {...sideMenuCommonFilterContext};
        console.log('newSideMenuCommonFilterContext', newSideMenuCommonFilterContext);
        Object.keys(rec).map(singleRecIndex => {
            newSideMenuCommonFilterContext[singleRecIndex] = new Set([rec[singleRecIndex]]);
        });
        setSideMenuCommonFilterContext(newSideMenuCommonFilterContext);
        let redirectPath = pageRoutes.dashboardPage;
        navigate(redirectPath);
    }

    const applyfilter = (filter) => {
        if (currentRetailer) {
            filter['source_retailer'] = currentRetailer;
        }
        if (currentSegment) {
            filter['segment'] = currentSegment;
        }
        const sortArray = sort.split("|");
        filter['sort'] = sortArray[0];
        filter['sortBy'] = sortArray[1];
        if (dateRange[0] && dateRange[1]) {
            filter['startDate'] = dateRange[0].format('YYYY-MM-DD HH:mm:ss');
            filter['endDate'] = dateRange[1].format('YYYY-MM-DD HH:mm:ss');
        }
        return filter;
    };

    const getCount = () => {
        setLoading(true);
        let api = '/categoryMapping/unableToMap';
        let filter = {
            limit: 0,
            page: 0,
            count: true
        };
        filter = applyfilter(filter);
        hardApiCall("GET", api, {}, filter)
        .then((response) => {
            setLoading(false);
            if (typeof response.data == 'object') {
                setCount(parseInt(response.data[0]['count']));
            } else {
                toast.error("There is an error in data response!");
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log("ERROR", error);
            toast.error("There is an error in data response!");
        });
    };

    const getdata = () => {
        setLoading(true);
        let api = '/categoryMapping/unableToMap';
        let filter = {
            limit: limit,
            page: page,
            count: false,
        };
        filter = applyfilter(filter);
        hardApiCall("GET", api, {}, filter)
        .then((response) => {
            setLoading(false);
            if (typeof response.data == 'object') {
                setData(response.data);
            } else {
                toast.error("There is an error in data response!");
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log("ERROR", error);
            toast.error("There is an error in data response!");
        });
    };

    useEffect(() => {
        getCount();
        let newSideMenuCommonFilterContext = {...sideMenuCommonFilterContext};
        newSideMenuCommonFilterContext['source_retailer'] = new Set([currentRetailer]);
        newSideMenuCommonFilterContext['segment'] = new Set([currentSegment]);
        newSideMenuCommonFilterContext['date_range'] = new Set(dateRange);
        setSideMenuCommonFilterContext(newSideMenuCommonFilterContext);
    },[currentRetailer, currentSegment, dateRange]);

    useEffect(() => {
        if (sideMenuCommonFilterLibContext.retailers.length) {
            setRetailerlist(sideMenuCommonFilterLibContext.retailers);
        }
    },[sideMenuCommonFilterLibContext]);

    useEffect(() => {
        getdata();
    }, [page, sort, currentRetailer, currentSegment, dateRange]);

    useEffect(() => {
        let pageinationArrNew = generatePagination(count, limit, page);
        setPageinationArr(pageinationArrNew);
    }, [page, count]);

    const renderPagination = () => {
        return (<Pagination className="mt-3 float-end">
            <InputGroup>
                <Button disabled variant="light" className="me-2">{generatePaginationString(count, limit, page)} </Button>
                <PaginationCustom
                    pageinationArr={pageinationArr}
                    pageClicked={pageClicked}
                    page={page}
                />
            </InputGroup>
        </Pagination>);
    };

    const pageClicked = (pageNo) => {
        setPage(pageNo);
    };

    const dateRangeSelect = (start, end, label) => {
        if (start.isValid() && end.isValid()) {
            setDateRange([start, end]);
        } else {
            if (dateRange[0] != null && dateRange[1] != null) {
                setDateRange([null, null]);
            }
        }
    };

    return (<>
        <Loader loading={loading} doNotOverFlow={true} />
        <Row className="bg-white p-3 pt-4">
            <Col>
                <div className="filter-name">
                    <div className="name-top">Select Retailer</div>
                    <Select 
                        onChange={e => {
                            if (e) {
                                setCurrentRetailer(e.value.toLowerCase());
                            } else {
                                setCurrentRetailer("");
                            }
                        }}
                        value={currentRetailer.length ? {
                            label: currentRetailer.toUpperCase(),
                            value: currentRetailer
                        } : null}
                        isClearable={true}
                        placeholder="Search Retailer"
                        options={retailerList.map(retailerListSingle => {
                            return {
                                label: retailerListSingle.toUpperCase(),
                                value: retailerListSingle.toLowerCase()
                            }
                        })}
                    />
                </div>
            </Col>
            <Col>
                <div className="filter-name">
                    <div className="name-top">Select Segment</div>
                    <Select 
                        onChange={e => {
                            if (e) {
                                setCurrentSegment(e.value.toLowerCase());
                            } else {
                                setCurrentSegment("");
                            }
                        }}
                        value={currentSegment.length ? {
                            label: segmentLib.find(segmentLibSingle => {
                                return segmentLibSingle.toLocaleLowerCase() == currentSegment;
                            }),
                            value: currentSegment
                        } : null}
                        isClearable={true}
                        placeholder="Search Source Segment"
                        options={segmentLib.map(segmentSingle => {
                            return {
                                label: segmentSingle,
                                value: segmentSingle.toLowerCase()
                            }
                        })}
                    />
                </div>   
            </Col>
            <Col sm="2">
                <div className="filter-name">
                    <DateRangePicker
                        initialSettings={{ 
                            startDate: dateRange[0], 
                            endDate: dateRange[1],
                            ranges: {
                                'All': [null, null],
                                'Today': [moment(), moment()],
                                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                'This Week': [moment().startOf('week').add(1, 'days'), moment().endOf('week').add(1, 'days')],
                                'Last Week': [moment().subtract(1, 'weeks').startOf('week').add(1, 'days'), moment().subtract(1, 'weeks').endOf('week').add(1, 'days')],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                'Last 90 Days': [moment().subtract(89, 'days'), moment()]
                            },
                            showCustomRangeLabel: false,
                        }}
                        onCallback={dateRangeSelect}
                    >
                        <button className="btn btn-sm btn-outline-secondary w-100"><FontAwesomeIcon icon={faCalendarAlt} /> {(!dateRange[0] && !dateRange[1])
                            ? "ALL" 
                            : ((dateRange[0].format('YYYY-MM-DD') == dateRange[1].format('YYYY-MM-DD')) 
                                ? dateRange[0].format('YYYY-MM-DD') 
                                : (dateRange[0].format('YYYY-MM-DD') + " - " + dateRange[1].format('YYYY-MM-DD')))} 
                        </button>
                    </DateRangePicker>
                </div>
            </Col>
        </Row>
        <Row>
            <Col sm="12"><hr className="separator-hr"/></Col>
        </Row>
        <Row className="bg-white pe-5 p-3 pt-4">
            <Col sm="12" className="row-sections">
                <Table borderless size="sm">
                    <thead>
                        <tr>
                            <th>
                                <RowLabel 
                                    label="Retailer"
                                    sort={sort}
                                    setSort={setSort}
                                    value={'source_retailer'}
                                />
                            </th>
                            <th>
                                <RowLabel 
                                    label="Segments"
                                    sort={sort}
                                    setSort={setSort}
                                    value={'segment'}
                                />
                            </th>
                            <th>
                                <RowLabel 
                                    label="Cat"
                                    sort={sort}
                                    setSort={setSort}
                                    value={'source_cat'}
                                />
                            </th>
                            <th>
                                <RowLabel 
                                    label="Sub Cat"
                                    sort={sort}
                                    setSort={setSort}
                                    value={'source_subcat'}
                                />
                            </th>
                            <th>
                                <RowLabel 
                                    label="Sub Sub Cat"
                                    sort={sort}
                                    setSort={setSort}
                                    value={'source_sub_subcat'}
                                />
                            </th>
                            <th>
                                <RowLabel 
                                    label="Type"
                                    sort={sort}
                                    setSort={setSort}
                                    value={'source_tag'}
                                />
                            </th>
                            <th>Reason</th>
                            <th className="float-end">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length ? data.map(singleData => {
                            return (<tr key={JSON.stringify(singleData)}>
                                <td>{singleData.source_retailer}</td>
                                <td>{singleData.segment}</td>
                                <td>{singleData.source_cat.toLowerCase()}</td>
                                <td>{singleData.source_subcat.toLowerCase()}</td>
                                <td>{singleData.source_sub_subcat ? (singleData.source_sub_subcat.toLowerCase() + (singleData.source_sub_sub_subcat ? (" | " + singleData.source_sub_sub_subcat.toLowerCase()) : "")) : ""}</td>
                                <td>{singleData.source_tag}</td>
                                <td>{singleData.reason_for_rejection}</td>
                                <td className="float-end">
                                    <button onClick={e => {
                                        mapRecord({
                                            source_retailer: singleData.source_retailer,
                                            segment: singleData.segment,
                                            source_cat: singleData.source_cat,
                                            source_subcat: singleData.source_subcat,
                                            source_sub_subcat: singleData.source_sub_subcat,
                                            source_sub_sub_subcat: singleData.source_sub_sub_subcat,
                                            source_tag: singleData.source_tag
                                        })
                                    }} className="btn btn-sm btn-outline-primary">Map</button>
                                </td>
                            </tr>);
                        }) : <tr>
                            <td colSpan={7}>No Data</td>
                        </tr>}
                    </tbody>
                </Table>
            </Col>
        </Row>
        <Row>
            <Col sm="12" >{renderPagination()}</Col>
        </Row>
    </>);
};

export default ReportUnableMapped;